const g:any = window[ENV.projectName] = window[ENV.projectName] || {};

import polyfillHelper from '../_modules/polyfillHelper';
polyfillHelper();
require('../_modules/head');

import gsap, { Expo, Cubic, Sine } from 'gsap/gsap-core';
import { CSSPlugin } from 'gsap/src/CSSPlugin';
gsap.registerPlugin(CSSPlugin);

import { mainStore } from '../_store/main';
import { LayoutMode, SimpleLayoutMode } from '../_types/common';

import OnScreenItemManager from './OnScreenItemManager';
import OnScreenItem from './OnScreenItem';
import OnScreenImg from './OnScreenImg';
// import OnScreenTxt from './OnScreenTxt';

import RootLoading from '../_modules/RootLoading';
import Header from '../_modules/Header';
import Footer from '../_modules/Footer';
import Menu, { MenuButton } from '../_modules/Menu';

import PageKV from './PageKV';

import MainGL from '../_modules/webgl/MainGL';

import { Vector2 } from 'three/src/math/Vector2';

import { ShapeKey, ShapeKeys, PointCloudDefinition } from '../_modules/webgl/PointCloud';

const SCROLL_STATE_CONTACT = 'CONTACT';
const SCROLL_STATE_KV = 'KV';

export default class PageComponent {
  public bodyElm: HTMLBodyElement = document.body as HTMLBodyElement;
  public htmlElm: HTMLHtmlElement = this.bodyElm.parentElement as HTMLHtmlElement;

  public scroller: HTMLElement = this.bodyElm.querySelector('.js-scroller') as HTMLHtmlElement;
  public scrollerSpacer: HTMLElement = this.bodyElm.querySelector('.js-scrollerSpacer') as HTMLHtmlElement;
  public scrollHeight: number = 0;

  protected header!: Header;
  protected menu!: Menu;

  protected mqL!:MediaQueryList;
  protected mqLL!:MediaQueryList;
  protected mqM!:MediaQueryList;
  protected mqSS!:MediaQueryList;

  protected resizeTimer: number | null = null;
  protected scrollToTweenObj!: { value: number };

  protected onScreenItemManager!: OnScreenItemManager;

  protected isLocked: boolean = true;

  protected mainGL!: MainGL<PointCloudDefinition>;

  protected pageKV!: PageKV;
  protected pageKVObserver!: IntersectionObserver;

  protected beforeScrollTop: number = window.scrollY || window.pageYOffset;
  protected scrollDir: number = 1;
  protected scrollState: string = '';

  protected contactLinkEl!: HTMLElement;
  protected contactLinkImgEl!: HTMLElement;
  protected contactLinkObserver!: IntersectionObserver;

  constructor() {
    this.bodyElm = document.body as HTMLBodyElement;

    g.scrollTo = this.scrollTo.bind(this);
    g.getOffsetTop = this.getOffsetTop.bind(this);
    g.getOffsetLeft = this.getOffsetLeft.bind(this);
    g.updateScrollerSize = this.updateScrollerSize.bind(this);

    this.mqL = window.matchMedia("(min-width: 800px)");
    this.mqLL = window.matchMedia("(min-width: 1440px)");
    this.mqM = window.matchMedia("(min-width:800px) and (max-width: 1024px)");
    this.mqSS = window.matchMedia("(max-width: 413px)");

    this.onScreenItemManager = new OnScreenItemManager();
  }

  protected async init(): Promise<any> {
    this.scrollToTweenObj = { value: window.scrollY || window.pageYOffset };

    await Promise.all([
      g.loadFontPromise,
      this.initLoading(),
      this.initHeader(),
      this.initFooter(),
      this.initMenu(),
    ]);

    await this.initContents();

    this.initMediaQuery();
    this.initScroll();
    this.initResize();

    this.resizeHandler();

    mainStore.setScrolled(false);
    mainStore.setLoaded(true);

  }

  protected async initContents(): Promise<void> {
    await Promise.all([
      this.initOnScreenItems(),
      // this.initOnScreenTxts(),
      this.initOnScreenImgs(),
    ]);
  }

  protected async initLoading(): Promise<void> {
    const rootLoading: RootLoading = new RootLoading({
      el: this.bodyElm.querySelector('.js-rootLoading') as Element,
    });
    await rootLoading.$nextTick();
  }

  protected async initMenu(): Promise<void> {
    this.menu = new Menu({
      el: this.bodyElm.querySelector('.js-menu') as Element,
    });
    const menuButton = new MenuButton({
      el: this.bodyElm.querySelector('.js-menuButton') as Element,
    });
    await Promise.all([
      await this.menu.$nextTick(),
      await menuButton.$nextTick(),
    ]);
  }

  protected async initHeader(): Promise<void> {
    this.header = new Header({
      el: this.bodyElm.querySelector('.js-header') as Element,
    });
    await this.header.$nextTick();
  }

  protected async initFooter(): Promise<void> {
    const footer: Footer = new Footer({
      el: this.bodyElm.querySelector('.js-footer') as Element,
    });
    await footer.$nextTick();
  }

  protected initScroll(): void {
    this.scrollHandler = this.scrollHandler.bind(this);
    this.onWheel = this.onWheel.bind(this);
    this.cancelScrollToAnimation = this.cancelScrollToAnimation.bind(this);

    window.addEventListener('scroll', this.scrollHandler, { passive: true });
    // window.addEventListener('wheel', this.cancelScrollToAnimation, { passive: false });
    window.addEventListener('wheel', this.onWheel, { passive: false });
    window.addEventListener('touchmove', this.cancelScrollToAnimation, { passive: false });
  }

  protected cancelScrollToAnimation(e?:Event): void {
    this.killScrollTween();
    if(this.isLocked) {
      e?.preventDefault();
      e?.stopImmediatePropagation();
    }
  }


  protected onWheel(e:WheelEvent): void {
    this.killScrollTween();
    if(this.isLocked) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }

  protected scrollHandler(e?:Event): void {
    mainStore.setScrollTop(window.scrollY || window.pageYOffset);
    // const isScrolled: boolean = mainStore.scrollTop > mainStore.windowHeight - g.getHeaderOffset() - 1;
    const isScrolled: boolean = false;
    this.updateScrollerScrolltop();

    this.onScroll();
  }

  // init resize action
  protected initResize() {
    if (g.md.mobile()) {
      this.resizeTimer = null;
      this.mobileResizeHanndler = this.mobileResizeHanndler.bind(this);
      window.addEventListener('orientationchange', this.mobileResizeHanndler, { passive: true });
    } else {
      this.resizeHandler = this.resizeHandler.bind(this);
      window.addEventListener('resize', this.resizeHandler, { passive: true });
    }
  }

  protected mobileResizeHanndler(e?: Event): void {
    if (this.resizeTimer != null) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = null;
    }
    this.resizeTimer = window.setTimeout((() => {
      this.resizeHandler();
    }), g.isAndroid? 300: 100);
  }

  protected async resizeHandler(e?: Event) {
    mainStore.setWindowSize({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    this.mediaQueryHandler();
    this.scrollHandler();
    this.menu.onResize();
    this.onResize();

    await this.menu.$nextTick();
    this.updateScrollerSize();
    this.updateScrollerScrolltop();
  }

  protected initMediaQuery(): void {
    this.mediaQueryHandler(null, true);
  }

  // change break point
  protected mediaQueryHandler(e: Event | null = null, checkOnly: boolean = false): void {
    if (this.mqLL.matches) {
      if(mainStore.layoutMode === LayoutMode.LL) return;
      mainStore.setLayoutMode(LayoutMode.LL);
      mainStore.setSimpleLayoutMode(SimpleLayoutMode.L);
      console.log('layout mode ' + LayoutMode.LL);

    } else if(this.mqM.matches) {
      if(mainStore.layoutMode === LayoutMode.M) return;
      mainStore.setLayoutMode(LayoutMode.M);
      mainStore.setSimpleLayoutMode(SimpleLayoutMode.L);
      console.log('layout mode ' + LayoutMode.M);

    } else if(this.mqL.matches) {
      if(mainStore.layoutMode === LayoutMode.L) return;
      mainStore.setLayoutMode(LayoutMode.L);
      mainStore.setSimpleLayoutMode(SimpleLayoutMode.L);
      console.log('layout mode ' + LayoutMode.L);

    } else if(this.mqSS.matches) {
      if(mainStore.layoutMode === LayoutMode.SS) return;
      mainStore.setLayoutMode(LayoutMode.SS);
      mainStore.setSimpleLayoutMode(SimpleLayoutMode.S);
      console.log('layout mode ' + LayoutMode.SS);

    } else {
      if(mainStore.layoutMode === LayoutMode.S) return;
      mainStore.setLayoutMode(LayoutMode.S);
      mainStore.setSimpleLayoutMode(SimpleLayoutMode.S);
      console.log('layout mode ' + LayoutMode.S);

    }
  }

  protected killScrollTween(): void {
    gsap.killTweensOf(this.scrollToTweenObj);
  }

  public updateScrollerSize() {
    if(g.isiOS || g.isIE11) return;
    const h = this.scroller.offsetHeight;
    this.scrollerSpacer.style.height = `${h}px`;
    this.scrollHeight = Math.max(0, h - window.innerHeight);
  }

  public updateScrollerScrolltop() {
    if(g.isiOS || g.isIE11) return;
    gsap.killTweensOf(this.scroller);
    const to = -Math.min(this.scrollHeight, Math.max(0, window.scrollY || window.pageYOffset));
    gsap.to(this.scroller, 0.8, { ease: Expo.easeOut, y: to });
  }

  // scroll to
  public async scrollTo(posTo: number, duration: number = 2, delay: number = 0, ease = Cubic.easeInOut, isCancelable:boolean = true, onStart?: Function, onEnd?: Function): Promise<void> {
    const scrollHeight = (this.bodyElm.scrollHeight || this.htmlElm.scrollHeight) - window.innerHeight;
    posTo = Math.min(posTo, scrollHeight);

    this.killScrollTween();

    return new Promise((resolve) => {
      const posFrom = window.scrollY || window.pageYOffset;

      if (posTo === posFrom) return resolve();

      const scrollToTweenObj = { value: posFrom };
      if(isCancelable) {
        this.scrollToTweenObj = scrollToTweenObj;
      }

      gsap.to(scrollToTweenObj, duration, {
        value: posTo,
        overwrite: true,
        ease,
        delay,
        onStart: () => {
          if (onStart !== undefined) onStart();
        },
        onUpdate: () => {
          window.scrollTo(0, scrollToTweenObj.value);
        },
        onComplete: () => {
          if (onEnd !== undefined) onEnd();
          return resolve();
        }
      });
    });
  }

  public getOffsetTop(el: HTMLElement) {
    if(el.offsetParent && !el.offsetParent.classList.contains('js-scroller')) {
      return el.offsetTop + this.getOffsetTop(el.offsetParent as HTMLElement);
    }
    return el.offsetTop;
  }

  public getOffsetLeft(el: HTMLElement) {
    if(el.offsetParent && !el.offsetParent.classList.contains('js-scroller')) {
      return el.offsetLeft + this.getOffsetLeft(el.offsetParent as HTMLElement);
    }
    return el.offsetLeft;
  }

  protected initOnScreenItems() {
    const promises: Promise<any>[] = [];
    const els = this.bodyElm?.querySelectorAll<HTMLElement>('.js-onScreenItem');
    if(!els) return;
    for (let i = 0, l = els.length; i < l; i++) {
      const item: OnScreenItem = new OnScreenItem({ el: els[i] });
      promises.push(this.onScreenItemManager.initItem(item));
    }
    return Promise.all(promises);
  }

  protected async initOnScreenImgs() {
    const promises: Promise<any>[] = [];
    const els = this.bodyElm?.querySelectorAll<HTMLElement>('.js-onScreenImg');
    if(!els) return;
    for (let i = 0, l = els.length; i < l; i++) {
      const item: OnScreenImg = new OnScreenImg({ el: els[i] });
      promises.push(this.onScreenItemManager.initItem(item));
    }
    return Promise.all(promises);
  }

  // protected async initOnScreenTxts() {
  //   const promises: Promise<any>[] = [];
  //   const els = this.bodyElm?.querySelectorAll<HTMLElement>('.js-onScreenTxt');
  //   if(!els) return;
  //   for (let i = 0, l = els.length; i < l; i++) {
  //     const item: OnScreenTxt = new OnScreenTxt({ el: els[i] });
  //     promises.push(this.onScreenItemManager.initItem(item));
  //   }
  //   return Promise.all(promises);
  // }

  protected onResize(): void {}

  protected onScroll(): void {
    this.scrollDir = Math.sign(mainStore.scrollTop - this.beforeScrollTop);
    this.beforeScrollTop = mainStore.scrollTop;
  }

  public play(): void {
    if(this.onScreenItemManager) {
      this.onScreenItemManager.setAutoExec(true);
      this.onScreenItemManager.exec();
    }
  }

  get isKV() { return this.scrollState === SCROLL_STATE_KV }
  get isContact() { return this.scrollState === SCROLL_STATE_CONTACT }

  protected async initContact() {
    this.contactLinkEl = this.bodyElm.querySelector('.js-contactLink') as HTMLElement;
    this.contactLinkImgEl = this.contactLinkEl.querySelector('.js-contactLinkImg') as HTMLElement;
  }

  protected initContactLinkObserver() {
    if(!window.IntersectionObserver || g.isGoogleBot) return;

    this.contactLinkObserver = new IntersectionObserver((entries)=> {
      const entry = entries[0];
      if(entry.isIntersecting) {
        if(this.scrollState === '' || (!this.isContact && this.scrollDir === 1)) {
          this.scrollState = SCROLL_STATE_CONTACT;
          console.log('scroll state:', SCROLL_STATE_CONTACT);
          this.mainGL.setMode(SCROLL_STATE_CONTACT);
        }

        if(this.scrollDir === 1) {
          this.bodyElm.classList.remove('is-orangeBg');
          this.mainGL.setOrange(false);
        }
      }
    }, {
      threshold: [0, 1],
      rootMargin: '-30% 0px'
    });
    this.contactLinkObserver.observe(this.contactLinkEl);
  }

  protected async initPageKV() {
    this.pageKV = new PageKV({ el: this.bodyElm.querySelector('.js-pageKV') as HTMLElement });
    await this.pageKV.$nextTick();
  }

  protected initPageKVObserver() {
    if(!window.IntersectionObserver || g.isGoogleBot) return;

    this.pageKVObserver = new IntersectionObserver((entries)=> {

      const entry = entries[0];
      if(entry.isIntersecting) {
        if(!this.isLocked) this.pageKV.play();
        if(this.scrollState === '' || (!this.isKV && this.scrollDir === -1)) {
          this.scrollState = SCROLL_STATE_KV;
          console.log('scroll state:', SCROLL_STATE_KV);
          this.mainGL.setMode('KV');
        }
      }
    }, {
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      rootMargin: `0px 0px`
    });
    this.pageKVObserver.observe(this.pageKV.$el);
  }

  protected getOffsetImgItem(el: HTMLElement, isCenter: boolean = false) {
    if(!el) return new Vector2();

    const offsetLeft = g.getOffsetLeft(el);
    const offsetTop = g.getOffsetTop(el);
    const halfSize = el.offsetWidth * 0.5;
    return new Vector2(
      offsetLeft + (isCenter? 0: halfSize),
      offsetTop + (isCenter? 0: halfSize)
    )
  }

  protected async wait(duration: number) {
    return new Promise((resolve)=> {
      window.setTimeout(()=> {
        resolve();
      }, duration)
    })
  }
}





