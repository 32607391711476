const g:any = window[ENV.projectName] = window[ENV.projectName] || {};

import gsap, {
  Expo,
  Linear,
  Sine
} from 'gsap/src/gsap-core';

import { mainStore } from '../../_store/main';

import { Texture           } from 'three/src/textures/Texture'            ;
import { Vector2           } from 'three/src/math/Vector2'                ;
import { Points            } from "three/src/objects/Points"              ;
import { RawShaderMaterial } from 'three/src/materials/RawShaderMaterial' ;
import { BufferGeometry    } from 'three/src/core/BufferGeometry'         ;
import { BufferAttribute   } from 'three/src/core/BufferAttribute'        ;

const NUM_VERTICES = 100;
const SIZE_BASIS = 512;

export default class BgPointCloud {
  public points!: Points;

  protected pointTexture!: Texture;
  protected material!: RawShaderMaterial;
  protected geomery!: BufferGeometry;

  constructor(pointTexture: Texture) {
    this.pointTexture = pointTexture;
    this.initPoints();
  }

  protected initPoints() {
    this.material = new RawShaderMaterial({
      vertexShader: require('./glsl/bgPoints.vert').default,
      fragmentShader: require('./glsl/points.frag').default,
      transparent: true,
      depthWrite: false,
      depthTest: false,
      uniforms: {
        time: { value: 0 },
        pointTexture: { value: this.pointTexture },
        scrollTop: { value: window.scrollY || window.pageYOffset },
        resolution: { value: new Vector2() },
        whiteFactor: { value: 0 },
        scale: { value: new Vector2() },
        alpha: { value: 0 },
      }
    });

    this.geomery = new BufferGeometry();

    const vertices: number[] = [];
    const vertexIndices: number[] = [];
    const randomValues: number[] = [];
    const HALF_SIZA_BASIS =  SIZE_BASIS* 0.5;

    for (let i = 0; i < NUM_VERTICES; i++) {
      vertices.push(-HALF_SIZA_BASIS + Math.floor(Math.random() * SIZE_BASIS));
      vertices.push(-HALF_SIZA_BASIS + Math.floor(Math.random() * SIZE_BASIS));
      vertices.push(0);
      randomValues.push(this.getRandomValue());
      randomValues.push(this.getRandomValue());
      randomValues.push(this.getRandomValue());
      randomValues.push(this.getRandomValue());
      vertexIndices.push(i);
    }
    this.geomery.setAttribute('position', new BufferAttribute(new Float32Array(vertices), 3));
    this.geomery.setAttribute('vertexIndex', new BufferAttribute(new Float32Array(vertexIndices), 1));
    this.geomery.setAttribute('randomValues', new BufferAttribute(new Float32Array(randomValues), 4));
    this.points = new Points(this.geomery, this.material);
    this.points.frustumCulled = false;
    this.points.matrixAutoUpdate = false;
    this.points.renderOrder = 0;
  }

  protected getRandomValue() {
    return (Math.random() + Math.random() + Math.random()) / 3;
  }

  public update(time: number) {
    this.material.uniforms.time.value = time;
    this.material.uniforms.resolution.value.x = window.innerWidth;
    this.material.uniforms.resolution.value.y = mainStore.glHeight;
    this.material.uniforms.scale.value.x = window.innerWidth * 1.1 / SIZE_BASIS;
    this.material.uniforms.scale.value.y = mainStore.glHeight * 1.1 / SIZE_BASIS;
  }

  public setScrollTop() {
    gsap.killTweensOf(this.material.uniforms.scrollTop);
    gsap.to(this.material.uniforms.scrollTop, 2, { ease: Expo.easeOut, value: mainStore.scrollTop });
  }


  public setOrange(isOrange: boolean) {
    gsap.killTweensOf(this.material.uniforms.whiteFactor);
    gsap.to(this.material.uniforms.whiteFactor, 0.4, { ease: Linear.easeNone, value: isOrange? 1: 0 });
  }

  public play() {
    gsap.killTweensOf(this.material.uniforms.alpha);
    gsap.to(this.material.uniforms.alpha, 0.4, { value: 1, ease: Linear.easeNone });
  }
}
