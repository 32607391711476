const g:any = window[ENV.projectName] = window[ENV.projectName] || {};

import gsap, {
  Expo,
  Linear,
  Sine,
  Cubic
} from 'gsap/src/gsap-core';

import preloadImg from '../../_utils/img/preloadImg';
import { mainStore } from '../../_store/main';
import SwappableRenderTexture from './SwappableRenderTexture';

import { Texture           } from 'three/src/textures/Texture'            ;
import { RawShaderMaterial } from 'three/src/materials/RawShaderMaterial' ;
import { Vector2           } from 'three/src/math/Vector2'                ;
import { Points            } from "three/src/objects/Points"              ;
import { BufferGeometry    } from 'three/src/core/BufferGeometry'         ;
import { BufferAttribute   } from 'three/src/core/BufferAttribute'        ;
import { DataTexture       } from 'three/src/textures/DataTexture'        ;
import { WebGLRenderTarget } from 'three/src/renderers/WebGLRenderTarget' ;
import { WebGL1Renderer    } from 'three/src/renderers/WebGL1Renderer'    ;
import { Scene             } from 'three/src/scenes/Scene'                ;
import { Vector4           } from 'three/src/math/Vector4'                ;

import { RGBAFormat, FloatType, NearestFilter, HalfFloatType, LogLuvEncoding } from 'three/src/constants';

const ORIGIN = window.location.origin;

const DATA_IMAGE_SIZE = 1024;
const DATA_CANVAS_SIZE = 512;

type ShapeData = {
  imgSrc: string,
  texture?: Texture,
  randomRBaseValue: number
  randomThreshold: number
};

export const ShapeKeys = {
  BRAIN   : 'BRAIN'   ,
  IOT     : 'IOT'     ,
  BIM     : 'BIM'     ,
  CHAIN   : 'CHAIN'   ,
  IEGAO   : 'IEGAO'   ,
  HANDS   : 'HANDS'   ,
  HOUSE   : 'HOUSE'   ,
  LOGO    : 'LOGO'    ,
  CONTACT : 'CONTACT' ,
} as const;
export type ShapeKey = typeof ShapeKeys[keyof typeof ShapeKeys];

export const ShapesData: { [P in ShapeKey]: ShapeData } = {
  BRAIN   : { imgSrc: '/assets/img/shapes/brain.png'   , texture: undefined, randomRBaseValue: 200  , randomThreshold: 0.8 },
  IOT     : { imgSrc: '/assets/img/shapes/iot.png'     , texture: undefined, randomRBaseValue: 200  , randomThreshold: 0.8 },
  BIM     : { imgSrc: '/assets/img/shapes/bim.png'     , texture: undefined, randomRBaseValue: 200  , randomThreshold: 0.8 },
  CHAIN   : { imgSrc: '/assets/img/shapes/chain.png'   , texture: undefined, randomRBaseValue: 200  , randomThreshold: 0.8 },
  IEGAO   : { imgSrc: '/assets/img/shapes/iegao.png'   , texture: undefined, randomRBaseValue: 200  , randomThreshold: 0.8 },
  HANDS   : { imgSrc: '/assets/img/shapes/hands.png'   , texture: undefined, randomRBaseValue: 200  , randomThreshold: 0.8 },
  HOUSE   : { imgSrc: '/assets/img/shapes/house.png'   , texture: undefined, randomRBaseValue: 200  , randomThreshold: 0.8 },
  LOGO    : { imgSrc: '/assets/img/shapes/logo.png'    , texture: undefined, randomRBaseValue: 200  , randomThreshold: 0.8 },
  CONTACT : { imgSrc: '/assets/img/shapes/contact.png' , texture: undefined, randomRBaseValue: 1200 , randomThreshold: 0.4 },
};

// randomThreshold
// contact: 0.4, others: 0.8
// randomRBaseValue
// contact: 1200, others: 200
export type PointCloudDefinition = {
  [key: string]: PointCloudItemDefinition,
};
export type PointCloudItemDefinition = {
  key: string,
  shapeKey: ShapeKey | 'KV'
  order: number,
  isKV?: boolean,
  kvItems? : PointCloudItemDefinition[]
};

export type ImgScales = {
  [key: string]: number
}
export type ImgOffsets = {
  [key: string]: Vector2
}

export const DATA_TEXTURE_SIZE = 128;
export const NUM_VERTICES = DATA_TEXTURE_SIZE * DATA_TEXTURE_SIZE;

export default class PointCloud<T extends PointCloudDefinition> {
  protected definition!: T;

  protected modeKey!: string;
  protected kvIndex: number = 0;
  protected isSimpleMode: boolean = false;
  protected isPlaying: boolean = false;
  protected hasKV: boolean = false;

  public points!: Points;
  public renderer!: WebGL1Renderer;
  public scene!: Scene;

  protected pointTexture!: Texture;
  protected material!: RawShaderMaterial;
  protected geomery!: BufferGeometry;

  protected posTexture!: WebGLRenderTarget;

  protected renderTexture!: SwappableRenderTexture;
  protected imgScales: ImgScales = {};
  protected imgOffsets: ImgOffsets = {};
  protected dataMaterial!: RawShaderMaterial;

  protected incrementNumLoaded!: ()=> void;
  protected incrementNumTotal!: ()=> void;

  constructor(renderer: WebGL1Renderer, scene: Scene, isSimpleMode: boolean, definition: T, initialKey: string, pointTexture: Texture) {
    this.renderer = renderer;
    this.scene = scene;
    this.isSimpleMode = isSimpleMode;
    this.definition = definition;
    this.pointTexture = pointTexture;

    this.hasKV = !!this.definition.KV;
    this.modeKey = initialKey;

    if(this.isSimpleMode) this.update = this.updateSimple.bind(this);
  }

  public setModeKey(modeKey: string) { this.modeKey = modeKey; }
  public getModeKey() { return this.modeKey; }


  protected initRenderTexture() {
    const initialData: number[] = [];
    let randomR, randomAngle;
    const randomRBaseValue = Math.max(window.innerWidth, mainStore.glHeight);

    for (let i = 0; i < NUM_VERTICES; i++) {
      randomR = (0.4 + 0.6 * randomRBaseValue) * Math.sqrt(Math.random());
      randomAngle = Math.PI * 2 * Math.random();

      initialData.push(Math.cos(randomAngle) * randomR);
      initialData.push(Math.sin(randomAngle) * randomR);
      initialData.push(this.getRandomValue());
      initialData.push(this.getRandomValue());
    }
    const initialDataTexture = new DataTexture(new Float32Array(initialData), DATA_TEXTURE_SIZE, DATA_TEXTURE_SIZE, RGBAFormat, FloatType);
    initialDataTexture.generateMipmaps = false;
    initialDataTexture.minFilter = NearestFilter;
    initialDataTexture.magFilter = NearestFilter;

    this.dataMaterial = new RawShaderMaterial({
      vertexShader: require('./glsl/data.vert').default,
      fragmentShader: require('./glsl/data.frag').default,
      depthWrite: false,
      uniforms: {
        time: { value: 0 },

        size: { value: DATA_TEXTURE_SIZE },
        dataCanvasSize: { value: DATA_CANVAS_SIZE },
        numVertices: { value: NUM_VERTICES },
        texture: { value: initialDataTexture },
        toTexture: { value: initialDataTexture },
        pointerPos: { value: new Vector2() },
        scale: { value: 1 },
        offset: { value: new Vector2() },
        resolution: { value: new Vector2() },
        isPointerActive: { value: 1 },
      }
    })
    this.renderTexture = new SwappableRenderTexture(DATA_TEXTURE_SIZE, DATA_TEXTURE_SIZE, this.renderer, this.dataMaterial, {
      type: FloatType,
      format: RGBAFormat,
    })
  }

  protected initPoints() {
    if(this.isSimpleMode) {
      this.material = new RawShaderMaterial({
        vertexShader: require('./glsl/pointsSimple.vert').default,
        fragmentShader: require('./glsl/points.frag').default,
        transparent: true,
        depthWrite: false,
        depthTest: false,
        uniforms: {
          time: { value: 0 },
          pointTexture: { value: this.pointTexture },
          scrollTop: { value: window.scrollY || window.pageYOffset },
          scale: { value: 1 },
          resolution: { value: new Vector2() },
          whiteFactor: { value: 0 },
          isPlaying: { value: 0 },
          alpha: { value: 0 },
          isPointerActive: { value: 0 },
          pointerPos: { value: new Vector2() },

          kvValue: { value: new Vector4(0, 0, 0, 0) },
          modeValue: { value: [0, 0, 0, 0, 0, 0] },
          offsets: { value: [ new Vector2(), new Vector2(), new Vector2(), new Vector2(), new Vector2(), new Vector2()] },
          scales: { value: [0, 0, 0, 0, 0, 0] },

        }
      });
    } else {
      this.material = new RawShaderMaterial({
        vertexShader: require('./glsl/points.vert').default,
        fragmentShader: require('./glsl/points.frag').default,
        transparent: true,
        depthWrite: false,
        depthTest: false,
        uniforms: {
          time: { value: 0 },
          pointTexture: { value: this.pointTexture },
          dataTexture: { value: null },
          dataTextureSize: { value: DATA_TEXTURE_SIZE },
          dataCanvasSize: { value: DATA_CANVAS_SIZE },
          scrollTop: { value: window.scrollY || window.pageYOffset },
          scale: { value: 1 },
          resolution: { value: new Vector2() },
          whiteFactor: { value: 0 },
          isPlaying: { value: 0 },
          alpha: { value: 0 },
        }
      });
    }

    this.geomery = new BufferGeometry();

    const vertices: number[] = [];
    const vertexIndices: number[] = [];
    const randomValues: number[] = [];
    let randomR, randomAngle;
    const randomRBaseValue = Math.max(window.innerWidth, mainStore.glHeight);
    for (let i = 0; i < NUM_VERTICES; i++) {
      randomR = (0.4 + 0.6 * randomRBaseValue) * Math.sqrt(Math.random());
      randomAngle = Math.PI * 2 * Math.random();
      vertices.push(Math.cos(randomAngle) * randomR);
      vertices.push(Math.sin(randomAngle) * randomR);
      vertices.push(0);
      randomValues.push(this.getRandomValue() * 2 - 1);
      randomValues.push(this.getRandomValue() * 2 - 1);
      randomValues.push(this.getRandomValue() * 2 - 1);
      randomValues.push(this.getRandomValue() * 2 - 1);
      vertexIndices.push(i);
    }
    this.geomery.setAttribute('position', new BufferAttribute(new Float32Array(vertices), 3));
    this.geomery.setAttribute('vertexIndex', new BufferAttribute(new Float32Array(vertexIndices), 1));
    this.geomery.setAttribute('randomValues', new BufferAttribute(new Float32Array(randomValues), 4));
    this.points = new Points(this.geomery, this.material);
    this.points.frustumCulled = false;
    this.points.matrixAutoUpdate = false;
    this.points.renderOrder = 1;
  }

  protected getRandomValue() {
    return (Math.random() + Math.random() + Math.random()) / 3;
  }

  public async init(incrementNumLoaded: ()=> void, incrementNumTotal: ()=> void) {
    this.incrementNumLoaded = incrementNumLoaded;
    this.incrementNumTotal = incrementNumTotal;

    this.initPoints();
    if(!this.isSimpleMode) this.initRenderTexture();

    await this.createAllPointsData()

    if(!this.isSimpleMode) {
      this.renderTexture.render();
    }
  }

  protected async createAllPointsData() {
    const promises: Promise<any>[] = [];
    let kvIndex = 0;
    for(const key in this.definition) {
      const d = this.definition[key];
      if(d.isKV && d.kvItems) {
        // KVの場合
        for (const kvItem of d.kvItems) {
          promises.push(this.createPointsData(kvItem.shapeKey as ShapeKey, `kv${kvIndex++}Pos`));
        }

      } else {
        promises.push(this.createPointsData(d.shapeKey as ShapeKey, `pos${d.order}`));
      }
    }
    return Promise.all(promises);
  }

  protected shuffle(arr: any[]) {
    const _arr = arr.slice();
    for(var i = _arr.length - 1; i > 0; i--){
      var r = Math.floor(Math.random() * (i + 1));
      var tmp = _arr[i];
      _arr[i] = _arr[r];
      _arr[r] = tmp;
    }
    return _arr;
  }

  protected async createPointsData(shapeKey: ShapeKey, shaderVarName: string) {
    this.incrementNumTotal();
    const shapeData = ShapesData[shapeKey];
    const imgSrc = shapeData.imgSrc;
    const img: HTMLImageElement = await preloadImg(imgSrc);

    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = DATA_CANVAS_SIZE;
    canvas.height = DATA_CANVAS_SIZE;
    const context: CanvasRenderingContext2D | null = canvas.getContext('2d');

    if(!context) return;

    context.drawImage(img, 0, 0, DATA_IMAGE_SIZE, DATA_IMAGE_SIZE, 0, 0, DATA_CANVAS_SIZE, DATA_CANVAS_SIZE);
    const imgData = context.getImageData(0, 0, DATA_CANVAS_SIZE, DATA_CANVAS_SIZE);

    const positions: number[][] = [];
    const numPixels = imgData.data.length / 4;
    let colIndex, rowIndex;
    for (let i = 0; i < numPixels; i++) {
      const a = imgData.data[i * 4 + 3];
      colIndex = i % DATA_CANVAS_SIZE;
      rowIndex = Math.floor(i / DATA_CANVAS_SIZE);
      if(a !== 0) {
        positions.push([
          colIndex - DATA_CANVAS_SIZE * 0.5,
          rowIndex - DATA_CANVAS_SIZE * 0.5
        ]);
      }
    }
    img.src = '';
    context.clearRect(0, 0, DATA_CANVAS_SIZE, DATA_CANVAS_SIZE);

    const data: number[] = [];
    let _positions: number[][] = this.shuffle(positions);
    let randomValueX = 0;
    let randomValueY = 0;
    let randomR, randomAngle;

    for (let i = 0; i < NUM_VERTICES; i++) {
      if(_positions.length === 0) {
        _positions = this.shuffle(positions);
      }
      const position = _positions.shift();

      if(i > NUM_VERTICES * shapeData.randomThreshold) {
        randomR = Math.sqrt(Math.random()) * shapeData.randomRBaseValue;
        randomAngle = Math.PI * 2 * Math.random();
        randomValueX = Math.cos(randomAngle) * randomR;
        randomValueY = Math.sin(randomAngle) * randomR;
      }
      if(position) {
        data.push(position[0] + randomValueX);
        data.push(position[1] + randomValueY);
        data.push(0);
        data.push(0);
      }
    }

    if(this.isSimpleMode) {
      this.geomery.setAttribute(shaderVarName, new BufferAttribute(new Float32Array(data), 4));
    } else {
      const texture = new DataTexture(new Float32Array(data), DATA_TEXTURE_SIZE, DATA_TEXTURE_SIZE, RGBAFormat, FloatType);
      texture.minFilter = NearestFilter;
      texture.magFilter = NearestFilter;
      texture.generateMipmaps = false;
      texture.needsUpdate = true;
      ShapesData[shapeKey].texture = texture;
    }

    this.incrementNumLoaded();
  }

  public setParams(params: {
    [key: string]: { imgSize: number, offset: Vector2 }
  }) {
    if(this.isSimpleMode) {
      for (const key in this.definition) {
        const p = params[key] as { imgSize: number, offset: Vector2 };
        const index = this.definition[key].order;

        const scale = (p.imgSize / DATA_CANVAS_SIZE) || 1;
        this.imgScales[key] = scale;
        this.imgOffsets[key] = p.offset;
        this.material.uniforms.offsets.value[index] = p.offset;
        this.material.uniforms.scales.value[index] = scale;
      }
    } else {
      for (const key in this.definition) {
        const p = params[key] as { imgSize: number, offset: Vector2 };

        const scale = (p.imgSize / DATA_CANVAS_SIZE) || 1;
        this.imgScales[key] = scale;
        this.imgOffsets[key] = p.offset;
      }
      this.updateParams(this.modeKey);
    }
  }

  protected updateParams(modeKey: string) {
    const scale = this.imgScales[modeKey];
    const offset = this.imgOffsets[modeKey];
    gsap.killTweensOf(this.dataMaterial.uniforms.offset.value);
    gsap.killTweensOf(this.dataMaterial.uniforms.scale);
    gsap.to(this.dataMaterial.uniforms.scale, 2, { value: scale, ease: Expo.easeOut });
    gsap.to(this.dataMaterial.uniforms.offset.value, 0.4, { y: offset.y, ease: Expo.easeOut });
    gsap.to(this.dataMaterial.uniforms.offset.value, 0.8, { x: offset.x, ease: Expo.easeOut });
  }

  protected mix(x: number, y: number, a: number) {
    return x * (1 - a) + y * a;
  }

  public updateSimple(time: number, pointerX: number, pointerY: number) {
    this.material.uniforms.time.value = time;
    this.material.uniforms.resolution.value.x = window.innerWidth;
    this.material.uniforms.resolution.value.y = mainStore.glHeight;
    this.material.uniforms.pointerPos.value.x = pointerX;
    this.material.uniforms.pointerPos.value.y = pointerY;
  }

  public update(time: number, pointerX: number, pointerY: number) {
    this.dataMaterial.uniforms.time.value = time;

    this.dataMaterial.uniforms.texture.value = this.renderTexture.getTexture();
    this.dataMaterial.uniforms.texture.value.neeedsUpdate = true;
    this.dataMaterial.uniforms.resolution.value.x = window.innerWidth;
    this.dataMaterial.uniforms.resolution.value.y = mainStore.glHeight;
    this.dataMaterial.uniforms.pointerPos.value.x = pointerX;
    this.dataMaterial.uniforms.pointerPos.value.y = pointerY;

    this.dataMaterial.needsUpdate = true;
    this.renderTexture.swap();
    this.renderTexture.render();

    this.material.uniforms.time.value = time;
    this.material.uniforms.resolution.value.x = window.innerWidth;
    this.material.uniforms.resolution.value.y = mainStore.glHeight;
    this.material.uniforms.dataTexture.value = this.renderTexture.getTexture();
    this.material.uniforms.dataTexture.value.neeedsUpdate = true;
    this.material.needsUpdate = true;
  }

  public setKVIndex(index: number) {
    this.kvIndex = index;

    if(this.modeKey !== 'KV') return;

    if(this.isSimpleMode) {
      gsap.killTweensOf(this.material.uniforms.kvValue.value);
      gsap.to(this.material.uniforms.kvValue.value, 2.6, {
        x: index === 0? 1: 0,
        y: index === 1? 1: 0,
        z: index === 2? 1: 0,
        w: index === 3? 1: 0,
        ease: Linear.easeNone
      });
    } else {
      if(this.definition.KV?.kvItems) {
        this.dataMaterial.uniforms.toTexture.value = ShapesData[this.definition.KV.kvItems[index].shapeKey].texture;
        this.dataMaterial.needsUpdate = true;
      }
    }

  }

  public setMode(modeKey: string) {
    if(!this.isPlaying) return;

    this.setModeKey(modeKey);

    if(this.isSimpleMode) {
      if(this.hasKV && modeKey === 'KV') this.setKVIndex(0);

      const keys = Object.values(this.definition).sort((d1, d2)=> { return d1.order - d2.order }).map(d => d.key);

      gsap.killTweensOf(this.material.uniforms.modeValue.value);
      gsap.to(this.material.uniforms.modeValue.value, 2.6, {
        0: modeKey === keys[0]? 1: 0,
        1: modeKey === keys[1]? 1: 0,
        2: modeKey === keys[2]? 1: 0,
        3: modeKey === keys[3]? 1: 0,
        4: modeKey === keys[4]? 1: 0,
        5: modeKey === keys[5]? 1: 0,
        ease: Linear.easeNone
      });

    } else {
      if(modeKey === 'KV') {
        this.setKVIndex(0);
        if(this.definition.KV?.kvItems) {
          this.dataMaterial.uniforms.toTexture.value = ShapesData[this.definition.KV.kvItems[0].shapeKey].texture;
          this.dataMaterial.needsUpdate = true;
        }
      } else {
        this.dataMaterial.uniforms.toTexture.value = ShapesData[this.definition[modeKey].shapeKey].texture;
      }
      this.dataMaterial.uniforms.toTexture.value.needsUpdate = true;
      this.dataMaterial.needsUpdate = true;
      this.updateParams(this.modeKey);
    }
  }

  public setScrollTop() {
    // if(g.isiOS) {
    //   this.material.uniforms.scrollTop.value = mainStore.scrollTop;
    //   return;
    // }
    const duration = g.isiOS? 0.4: 0.8;
    gsap.killTweensOf(this.material.uniforms.scrollTop);
    gsap.to(this.material.uniforms.scrollTop, duration, { ease: Expo.easeOut, value: mainStore.scrollTop });
  }

  public setOrange(isOrange: boolean) {
    gsap.killTweensOf(this.material.uniforms.whiteFactor);
    gsap.to(this.material.uniforms.whiteFactor, 0.4, { ease: Linear.easeNone, value: isOrange? 1: 0 });
  }

  public setPointerActive(isPointerActiveValue: number) {
    const duration = isPointerActiveValue === 1? 0.4: 0.8;
    if(this.isSimpleMode) {
      // this.material.uniforms.isPointerActive.value = isPointerActiveValue;
      gsap.killTweensOf(this.material.uniforms.isPointerActive);
      gsap.to(this.material.uniforms.isPointerActive, duration, { value: isPointerActiveValue, ease: Expo.easeOut });
    } else {
      // this.dataMaterial.uniforms.isPointerActive.value = isPointerActiveValue;
      gsap.killTweensOf(this.dataMaterial.uniforms.isPointerActive);
      gsap.to(this.dataMaterial.uniforms.isPointerActive, duration, { value: isPointerActiveValue, ease: Expo.easeOut });
    }
  }

  public play() {
    this.isPlaying = true;

    if(this.isSimpleMode) {
      gsap.killTweensOf(this.material.uniforms.isPlaying);
      gsap.to(this.material.uniforms.isPlaying, 2, { value: 1, ease: Expo.easeOut });
    }

    gsap.killTweensOf(this.material.uniforms.alpha);
    gsap.to(this.material.uniforms.alpha, 0.4, { value: 1, ease: Linear.easeNone });
  }
}
