import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { mainStore } from '../_store/main';

const g:any = window[ENV.projectName] = window[ENV.projectName] || {};

import gsap, { Expo } from 'gsap/gsap-core';
import { emit } from 'process';

@Component
export default class IndexLoading extends Vue {
  protected isHidden: boolean = false;
  protected isLoaded: boolean = false;
  protected numTotal: number = 0;
  protected numLoaded: number = 0;
  protected percent: number = 0;
  protected percentTweenObj: { value: number } = { value: 0 };

  get classObj() {
    return {
      'is-loaded': this.isLoaded
    }
  }

  get barStyleObj() {
    return {
      transform: `scaleX(${this.percent * 0.01})`
    }
  }

  @Emit('onclickenter')
  protected onClickEnterEmitter() {
    return this;
  }

  public incrementNumTotal() {
    this.numTotal++;
    console.trace('total: ', this.numTotal);
  }

  public incrementNumLoaded() {
    this.numLoaded++;
    console.log(`loaded: ${this.numLoaded} / ${this.numTotal}`);
    const value = (this.numTotal === 0)? 0: Math.floor(this.numLoaded / this.numTotal * 100);
    gsap.killTweensOf(this.percentTweenObj);
    gsap.to(this.percentTweenObj, 0.4, { value, ease: Expo.easeOut, overwrite: true, onUpdate: ()=> {
      this.percent = Math.floor(this.percentTweenObj.value);
    }});

    if(this.numLoaded > 0 && this.numLoaded === this.numTotal) {
      setTimeout(()=> {
        this.isLoaded = true;
      }, 1000);
    }
  }

  protected onClickEnter() {
    this.isHidden = true;
    this.onClickEnterEmitter();
  }
}