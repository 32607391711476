
const g:any = window[ENV.projectName] = window[ENV.projectName] || {};

import { Component, Vue } from 'vue-property-decorator';
import { mainStore } from '../_store/main';

import PageComponent from '../_modules/PageComponent';
import IndexLoading from '../_modules/IndexLoading';
import IndexKV from '../_modules/IndexKV';
import IndexOthers from '../_modules/IndexOthers';

import MainGL from '../_modules/webgl/MainGL';

import { ShapeKeys, PointCloudDefinition } from '../_modules/webgl/PointCloud';
import { SimpleLayoutMode } from '../_types/common';


const IndexScrollState = {
  KV: 'KV',
  SERVICE: 'SERVICE',
  ABOUT: 'ABOUT',
  OTHERS: 'OTHERS',
  TOPICS: 'TOPICS',
  CONTACT: 'CONTACT',
} as const;

export const IndexGLDefinition: PointCloudDefinition = {
  KV: {
    key: 'KV',
    shapeKey: 'KV',
    isKV: true,
    order: 0,
    kvItems: [
      { key: '', shapeKey: ShapeKeys.BRAIN      , order: 0 },
      { key: '', shapeKey: ShapeKeys.IOT        , order: 1 } ,
      { key: '', shapeKey: ShapeKeys.BIM , order: 2 } ,
      { key: '', shapeKey: ShapeKeys.CHAIN      , order: 3 }
   ]
  },
  SERVICE : { key: 'SERVICE', shapeKey: ShapeKeys.IEGAO   , order: 1 },
  ABOUT   : { key: 'ABOUT'  , shapeKey: ShapeKeys.HOUSE   , order: 2 },
  CONTACT : { key: 'CONTACT', shapeKey: ShapeKeys.CONTACT , order: 3 },
};

export default class Main extends PageComponent {
  protected indexLoading!: IndexLoading;
  protected isOrangeBg: boolean = false;
  protected othersScrollRatio: number = 0;

  protected kv!: IndexKV;
  protected kvObserver!: IntersectionObserver;

  protected serviceEl!: HTMLElement;
  protected serviceImgEl!: HTMLElement;
  protected serviceObserver!: IntersectionObserver;

  protected aboutEl!: HTMLElement;
  protected aboutImgEl!: HTMLElement;
  protected aboutObserver!: IntersectionObserver;

  protected others!: IndexOthers;
  protected othersObserver!: IntersectionObserver;

  protected topicsEl!: HTMLElement;
  protected topicsObserver!: IntersectionObserver;
  protected othersOffsetTop: number = 0;

  protected isLockedByOthers: boolean = false;

  protected beforeTouchY: number = 0;

  constructor() {
    super();
    this.isLocked = true;
    this.init();
  }

  get isKV () { return this.scrollState === IndexScrollState.KV }
  get isService    () { return this.scrollState === IndexScrollState.SERVICE     }
  get isAbout      () { return this.scrollState === IndexScrollState.ABOUT       }
  get isOthers     () { return this.scrollState === IndexScrollState.OTHERS      }
  get isTopics     () { return this.scrollState === IndexScrollState.TOPICS      }
  get isContact    () { return this.scrollState === IndexScrollState.CONTACT     }

  protected async init(): Promise<any> {
    mainStore.setCurrentPageId('index');

    this.scrollToTweenObj = { value: window.scrollY || window.pageYOffset };

    await this.initIndexLoading();
    this.indexLoading.incrementNumTotal();

    await Promise.all([
      g.loadFontPromise,
      this.initMainGL(),
      this.initLoading(),
      this.initHeader(),
      this.initFooter(),
      this.initMenu(),
    ]);

    await this.initContents();

    setTimeout(()=> { this.indexLoading.incrementNumLoaded(); }, 1000)

    this.initMediaQuery();
    this.initScroll();
    this.initResize();

    this.resizeHandler();

    mainStore.setScrolled(false);
    mainStore.setLoaded(true);

    this.initObservers();
  }

  protected async initMainGL() {
    const mainGLEl = this.bodyElm.querySelector('.js-mainGL') as HTMLElement;

    this.mainGL = new MainGL<typeof IndexGLDefinition>(
      mainGLEl,
      mainGLEl.querySelector('canvas') as HTMLCanvasElement,
      IndexGLDefinition,
      'KV',
      false
    );

    this.mainGL.setCallbacks(
      ()=> this.indexLoading.incrementNumLoaded(),
      ()=> this.indexLoading.incrementNumTotal(),
    )
    await this.mainGL.init()
    .catch((e)=> {
      console.log(e);
      this.htmlElm.classList.add('is-noWebGL');
      mainStore.setNoWebGL(true);
    })
    this.mainGL.onResize();
  }

  protected async initObservers() {
    await this.wait(400);

    this.initKVObserver();
    this.initServiceObserver();
    this.initAboutObserver();
    this.initOthersObserver();
    this.initTopicsObserver();
    this.initContactLinkObserver();
  }

  protected async initIndexLoading(): Promise<void> {
    this.indexLoading = new IndexLoading({
      el: document.querySelector('.js-indexLoading') as HTMLElement
    });
    this.indexLoading.$on('onclickenter', ()=> {
      this.onScroll();
      this.onResize();
      this.play();
    })
    await this.indexLoading.$nextTick();
  }

  protected async initKV(): Promise<void> {
    this.kv = new IndexKV({
      el: document.querySelector('.js-indexKV') as HTMLElement
    });
    this.kv.$on('onclickindicator', (index: number)=> {
      this.mainGL.setKVIndex(index);
    })
    await this.kv.$nextTick();
  }

  protected initKVObserver() {
    if(!window.IntersectionObserver || g.isGoogleBot) return;

    this.kvObserver = new IntersectionObserver((entries)=> {

      const entry = entries[0];
      if(entry.isIntersecting) {
        if(!this.isLocked) this.kv.play();
        if(this.scrollState === '' || (!this.isKV && this.scrollDir === -1)) {
          this.scrollState = IndexScrollState.KV;
          console.log('scroll state:', IndexScrollState.KV);
          this.mainGL.setMode(IndexScrollState.KV);
        }
      } else {
        this.kv.pause();
      }
    }, {
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      rootMargin: `0px 0px`
    });
    this.kvObserver.observe(this.kv.$el);
  }

  protected async initService() {
    this.serviceEl = this.bodyElm.querySelector('.js-indexService') as HTMLElement;
    this.serviceImgEl = this.serviceEl.querySelector('.js-indexServiceImg') as HTMLElement;
  }

  protected initServiceObserver() {
    if(!window.IntersectionObserver || g.isGoogleBot) return;

    this.serviceObserver = new IntersectionObserver((entries)=> {
      const entry = entries[0];
      if(entry.isIntersecting) {
        if(this.scrollState === '' ||
          (!this.isService &&
            ((this.scrollDir === 1 && this.isKV) || (this.scrollDir === -1 && !this.isKV))
          )
        ) {
          this.scrollState = IndexScrollState.SERVICE;
          console.log('scroll state:', IndexScrollState.SERVICE);
          this.mainGL.setMode(IndexScrollState.SERVICE);

        }
      }
    }, {
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      rootMargin: '-20% 0px'
    });
    this.serviceObserver.observe(this.serviceEl);
  }

  protected async initAbout() {
    this.aboutEl = this.bodyElm.querySelector('.js-indexAbout') as HTMLElement;
    this.aboutImgEl = this.aboutEl.querySelector('.js-indexAboutImg') as HTMLElement;
  }

  protected initAboutObserver() {
    if(!window.IntersectionObserver || g.isGoogleBot) return;

    this.aboutObserver = new IntersectionObserver((entries)=> {
      const entry = entries[0];
      if(entry.isIntersecting) {
        if(this.scrollState === '' ||
          (!this.isAbout &&
            ((this.scrollDir === 1 && (this.isKV || this.isService)) || (this.scrollDir === -1 && !this.isKV && !this.isService))
          )
        ) {
          this.scrollState = IndexScrollState.ABOUT;
          this.bodyElm.classList.remove('is-orangeBg');
          this.mainGL.setMode(IndexScrollState.ABOUT);
          this.mainGL.setOrange(false);
          console.log('scroll state:', IndexScrollState.ABOUT);
        }
      }
    }, {
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      rootMargin: '-50% 0px'
    });
    this.aboutObserver.observe(this.aboutEl);
  }

  protected async initOthers() {
    const el = this.bodyElm.querySelector('.js-indexOthers') as HTMLElement;
    this.others = new IndexOthers({ el });
    await this.others.$nextTick();
  }

  protected initOthersObserver() {
    if(!window.IntersectionObserver || g.isGoogleBot) return;

    this.othersObserver = new IntersectionObserver((entries)=> {
      const entry = entries[0];
      if(entry.isIntersecting) {
        if(this.scrollState === '' ||
          (!this.isOthers &&
            ((this.scrollDir === 1 && !this.isTopics && !this.isContact) || (this.scrollDir === -1 && (this.isTopics || this.isContact)))
          )
        ) {
          this.scrollState = IndexScrollState.OTHERS;
          this.bodyElm.classList.add('is-orangeBg');
          this.others.setActive(true);
          this.others.setVisible(true);
          this.mainGL.setOrange(true);
          console.log('scroll state:', IndexScrollState.OTHERS);
        }
      } else {
        this.bodyElm.classList.remove('is-orangeBg');
        this.mainGL.setOrange(false);
      }
    }, {
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      rootMargin: '-50px 0px'
    });
    this.othersObserver.observe(this.others.inner);
  }

  protected async initTopics() {
    this.topicsEl = this.bodyElm.querySelector('.js-indexTopics') as HTMLElement;
  }

  protected initTopicsObserver() {
    if(!window.IntersectionObserver || g.isGoogleBot) return;

    this.topicsObserver = new IntersectionObserver((entries)=> {
      const entry = entries[0];
      if(entry.isIntersecting) {
        if(this.scrollState === '' ||
          (!this.isTopics &&
            ((this.scrollDir === 1 && !this.isContact) || (this.scrollDir === -1 && this.isContact))
          )
        ) {
          this.scrollState = IndexScrollState.TOPICS;
          console.log('scroll state:', IndexScrollState.TOPICS);
        }
      }
    }, {
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      rootMargin: '-50px 0px'
    });
    this.topicsObserver.observe(this.topicsEl);
  }


  protected async initContents(): Promise<void> {

    await Promise.all([
      this.initKV(),
      this.initService(),
      this.initAbout(),
      this.initOthers(),
      this.initTopics(),
      this.initContact()
    ]);

    await super.initContents();
  }

  protected async onResize() {
    this.others.onResize();
    await this.others.$nextTick();
    this.othersOffsetTop = this.others.getInnerOffsetTop();
    if(mainStore.simpleLayoutMode === SimpleLayoutMode.S) {
      this.isLockedByOthers = false;
    }
    this.mainGL.onResize();
    this.updateGLScales();
  }

  protected updateGLScales() {
    if(!this.kv || !this.mainGL) return;

    const kvImgEl = this.kv.getImgContainer()

    this.mainGL.setParams({
      KV: {
        imgSize: kvImgEl.offsetWidth || 1,
        offset: this.getOffsetImgItem(kvImgEl, true)
      },
      SERVICE: {
        imgSize: this.serviceImgEl.offsetWidth,
        offset: this.getOffsetImgItem(this.serviceImgEl)
      },
      ABOUT: {
        imgSize: this.aboutImgEl.offsetWidth,
        offset: this.getOffsetImgItem(this.aboutImgEl)
      },
      CONTACT: {
        imgSize: this.contactLinkImgEl.offsetWidth,
        offset: this.getOffsetImgItem(this.contactLinkImgEl)
      },
    })
  }

  protected onScroll(): void {
    super.onScroll();
    this.mainGL.setScrollTop();
  }

  protected initScroll(): void {
    super.initScroll();
    window.addEventListener('touchstart', this.onTouchStart.bind(this), { passive: false });
    window.addEventListener('touchmove', this.onTouchMove.bind(this), { passive: false });
  }

  protected onTouchStart(e: TouchEvent) {
    const touchY = e.touches[0].clientY;
    this.beforeTouchY = touchY;
  }

  protected onTouchMove(e: TouchEvent) {
    this.killScrollTween();

    const touchY = e.touches[0].clientY;
    const delta = -(touchY - this.beforeTouchY);
    this.beforeTouchY = touchY;

    if(this.isLocked) {
      e.preventDefault();
      e.stopImmediatePropagation();
      return;
    }

    if(!this.others.isScrollable) return;

    if(this.others.getActive()) {
      this.others.scroll(delta);

      if(delta > 0) {
        // 順方向
        if(mainStore.scrollTop >= this.othersOffsetTop && this.others.scrollRatio <= 0.8) {
          // スクロールロック
          this.killScrollTween();
          this.isLockedByOthers = true;
          window.scrollTo(0, this.othersOffsetTop);
        }

        if(this.others.scrollRatio >= 1.0) {
          this.others.setActive(false);

        } else if(this.others.scrollRatio > 0.8) {
          this.isLockedByOthers = false;
          this.others.setVisible(false);

        }

      } else {
        // 逆方向
        if(mainStore.scrollTop <= this.othersOffsetTop && this.others.scrollRatio >= 0.2) {
          // スクロールロック
          this.killScrollTween();
          this.isLockedByOthers = true;
          window.scrollTo(0, this.othersOffsetTop);
        }

        if(this.others.scrollRatio <= 0.0) {
          this.others.setActive(false);

        } else if(this.others.scrollRatio < 0.2) {
          this.isLockedByOthers = false;
          this.others.setVisible(false);

        }
      }
    }

    if(this.isLockedByOthers) {
      this.killScrollTween();
      window.scrollTo(0, this.othersOffsetTop);
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }

  protected onWheel(e: WheelEvent): void {
    this.killScrollTween();

    if(this.isLocked) {
      e.preventDefault();
      e.stopImmediatePropagation();
      return;
    }

    if(!this.others.isScrollable) return;

    const delta = e.deltaY;

    if(this.others.getActive()) {
      this.others.scroll(delta);

      if(delta > 0) {
        // 順方向
        if(mainStore.scrollTop >= this.othersOffsetTop && this.others.scrollRatio <= 0.8) {
          // スクロールロック
          this.killScrollTween();
          this.isLockedByOthers = true;
          window.scrollTo(0, this.othersOffsetTop);
        }

        if(this.others.scrollRatio >= 1.0) {
          this.others.setActive(false);

        } else if(this.others.scrollRatio > 0.8) {
          this.isLockedByOthers = false;
          this.others.setVisible(false);

        }

      } else {
        // 逆方向
        if(mainStore.scrollTop <= this.othersOffsetTop && this.others.scrollRatio >= 0.2) {
          // スクロールロック
          this.killScrollTween();
          this.isLockedByOthers = true;
          window.scrollTo(0, this.othersOffsetTop);
        }

        if(this.others.scrollRatio <= 0.0) {
          this.others.setActive(false);

        } else if(this.others.scrollRatio < 0.2) {
          this.isLockedByOthers = false;
          this.others.setVisible(false);

        }
      }
    }

    if(this.isLockedByOthers) {
      this.killScrollTween();
      window.scrollTo(0, this.othersOffsetTop);
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }



  public play(): void {
    this.mainGL.startLoop();
    this.updateGLScales();

    this.mainGL.play(()=> {
      this.isLocked = false;
      this.mainGL.setMode('KV');
      this.mainGL.setKVIndex(0);
      this.kv.play();
    });

    super.play();
  }
}