const g: any = window[ENV.projectName] || {};

import OnScreenItem from './OnScreenItem';

const ATTRIBUTE_NAME: string = 'data-on-screen-id';
const ROOT_MARGIN_TOP: number = 50;
const INTERSECTION_OBSERVER_DEFAULT_OPTIONS = {
  rootMargin: `-${ROOT_MARGIN_TOP}px 0px`
}

export default class OnScreenItemManager {
  protected isActive: boolean = false;
  protected observer!: IntersectionObserver;
  protected count: number = 0;
  public items: { [key: string]: OnScreenItem } = {};

  static readonly ROOT_MARGIN_TOP: number = ROOT_MARGIN_TOP;

  constructor(options: IntersectionObserverInit | null = null) {
    const ioOptions: IntersectionObserverInit = Object.assign({}, INTERSECTION_OBSERVER_DEFAULT_OPTIONS, options);
    if(window.IntersectionObserver && !g.isGoogleBot) {
      this.observer = new IntersectionObserver((entries)=> {
        entries.forEach((entry, index) => {
          if(entry.isIntersecting) {
            const id = entry.target.getAttribute(ATTRIBUTE_NAME);
            if(id && this.items[id]) {
              this.items[id].onIntersected(entry);
            }
          }
        });
      }, ioOptions);
      this.isActive = true;
    }
  }

  public getObserber(): IntersectionObserver | null {
    return this.observer;
  }

  public async initItem<T extends OnScreenItem>(component: T): Promise<any> {
    if(!component || !this.observer) return;

    const id = `item${this.count++}`;

    await component.initOnScreen(id, this.observer, ATTRIBUTE_NAME);
    this.items[id] = component;
  }

  public exec(): void {
    for (const key in this.items) {
      this.items[key].exec();
    }
  }

  public setAutoExec(autoPlay: boolean = true) {
    for (const key in this.items) {
      this.items[key].setAutoExec(autoPlay);
    }
  }

  public dispoase(): void {
    for (const key in this.items) {
      this.items[key].$destroy();
    }
    this.items = {};
  }
}