const g:any = window[ENV.projectName] = window[ENV.projectName] || {};

import { Component, Vue, Prop, Emit, Ref } from 'vue-property-decorator';
import { mainStore } from '../_store/main';
import OnScreenItem from './OnScreenItem';

import gsap, { Expo } from 'gsap/gsap-core';
import { SimpleLayoutMode } from '../_types/common';


@Component
export default class IndexOthers extends Vue {
  protected isActive: boolean = false;
  protected isVisible: boolean = false;

  @Ref('inner')
  public inner!: HTMLDivElement;

  @Ref('scroller')
  public scroller!: HTMLDivElement;

  public scrollWidth: number = 0;
  public scrollRatio: number = 0;
  public isScrolledAway: boolean = false;

  public onResize() {
    gsap.killTweensOf(this.scroller);
    if(mainStore.simpleLayoutMode === SimpleLayoutMode.L) {
      this.scrollWidth = Math.max(0, this.scroller.offsetWidth - mainStore.windowWidth);
      gsap.set(this.scroller, { x: -this.scrollWidth * this.scrollRatio });
    }
  }

  public setActive(isActive: boolean) { this.isActive = isActive; }
  public getActive() { return this.isActive; }

  public setVisible(isVisible: boolean) { this.isVisible = isVisible; }


  get classObj() {
    return {
      // 'is-active': this.isActive && this.isScrollable,
      'is-visible': this.isVisible || !this.isScrollable
    }
  }

  get isScrollable() {
    return mainStore.simpleLayoutMode !== SimpleLayoutMode.S && this.scrollWidth !== 0;

  }

  public scroll(delta: number) {
    if(!this.isScrollable || !this.isActive) return;

    gsap.killTweensOf(this.scroller);
    const to = Math.max(0, Math.min(this.scrollWidth, this.scrollWidth * this.scrollRatio + delta));
    this.scrollRatio = to / this.scrollWidth;
    gsap.to(this.scroller, 0.8, { ease: Expo.easeOut, x: -to });
  }

  public setScrolledAway(isScrolledAway: boolean) {
    this.isScrolledAway = isScrolledAway;
    this.scrollRatio = this.isScrolledAway? 1: 0;
  }

  public getInnerOffsetTop() {
    return g.getOffsetTop(this.$el);
  }
}