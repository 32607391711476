const g:any = window[ENV.projectName] = window[ENV.projectName] || {};

import { Component, Vue, Emit, Ref } from 'vue-property-decorator';
import { mainStore } from '../_store/main';

import gsap, { Expo } from 'gsap/src/gsap-core';


@Component
export class MenuButton extends Vue {
  public get isMenuOpened(): boolean {
    return mainStore.isMenuOpened;
  }

  public get classObj() {
    return {
      'is-menuOpened': this.isMenuOpened,
    }
  }

  public onClick() {
    mainStore.setMenuOpened(!mainStore.isMenuOpened);
  }
}

@Component
export default class Menu extends Vue {
  protected scrollerHeight: number = 0;
  protected scrollHeight: number = 0;
  protected scrollTop: number = 0;
  protected beforeY: number = 0;
  protected onClickMember: (e: MouseEvent)=> void = ()=> {};
  protected onClickMessage: (e: MouseEvent)=> void = ()=> {};

  @Ref()
  protected spacer!: HTMLElement;

  @Ref()
  protected scroller!: HTMLElement;

  protected get spacerStyleObj() {
    return {
      height: `${this.scrollerHeight}px`
    }
  }

  protected get scrollerStyleObj() {
    return {
      transform: `translateY(${-this.scrollTop}px)`
    }
  }

  public get isOpened(): boolean {
    return mainStore.isMenuOpened;
  }

  // computed
  public get classObj() {
    return {
      'is-opened': this.isOpened,
      'is-isNoAnim': !this.isOpened
    }
  }

  public onClickClose() {
    mainStore.closeMenu();
  }

  public setClickMember(callback: ()=> void) {
    this.onClickMember = (e: MouseEvent)=> {
      callback();
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }

  public setClickMessage(callback: ()=> void) {
    this.onClickMessage = (e: MouseEvent)=> {
      callback();
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }

  public onResize() {
    if(this.scroller) {
      this.scrollerHeight = this.scroller.offsetHeight;
      const scrollHeight = this.scroller.offsetHeight - window.innerHeight;
      this.scrollHeight = Math.max(0, scrollHeight);
      if(scrollHeight < 0) {
        gsap.to(this, 0.8, { ease: Expo.easeOut, scrollTop: 0 });
      }
    }
  }

  public onWheel(e: WheelEvent) {
    gsap.killTweensOf(this, { scrollTop: true });
    const to =  Math.max(0, Math.min(this.scrollHeight, this.scrollTop + e.deltaY * 4));
    gsap.to(this, 0.8, { ease: Expo.easeOut, scrollTop: to });
    e.stopImmediatePropagation();
    e.preventDefault()
  }

  public onTouchMove(e: TouchEvent) {
    const y = e.touches[0].pageY;
    const to =  Math.max(0, Math.min(this.scrollHeight, this.scrollTop + (this.beforeY - y) * 4));
    gsap.to(this, 0.8, { ease: Expo.easeOut, scrollTop: to });
    this.beforeY = y;

    e.stopImmediatePropagation();
    e.preventDefault()
  }

  public onTouchStart(e: TouchEvent) {
    this.beforeY = e.touches[0].pageY;
    e.stopPropagation();
  }

  public onBeforeEnter() {
    this.scrollTop = 0;
  }

  public onAfterEnter() {
    this.onResize();
  }

  public onBeforeLeave() {

  }

  public onAfterLeave() {
    this.scrollTop = 0;
  }

}