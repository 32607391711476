import { Component, Vue, Prop, Emit, Ref } from 'vue-property-decorator';
import { mainStore } from '../_store/main';

const g:any = window[ENV.projectName] = window[ENV.projectName] || {};

import gsap, { Expo } from 'gsap/gsap-core';

@Component({
  template: document.querySelector('.js-kvItemMainCopyLineTmpl')?.innerHTML
})
export class IndexKVItemMainCopyLine extends Vue {
  @Prop({ type: String, required: true })
  protected txtLine!: string;
  protected transitionDelay: string[] = [];

  get txtChars() {
    return this.txtLine.split('').map((char, index)=> {
      this.transitionDelay[index] = `${Math.random() * 0.6}s`;
      return char === ' '? '&nbsp;': char;
    });
  }
}

@Component({
  components: {
    maincopyline: IndexKVItemMainCopyLine
  },
  template: document.querySelector('.js-kvItemTmpl')?.innerHTML
})
export class IndexKVItem extends Vue {
  @Prop({ type: Array, required: true })
  protected description!: string[];

  @Prop({ type: Array, required: true })
  protected mainCopy!: string[];

  @Prop({ type: Number, required: true })
  protected index!: number;

  @Prop({ type: Number, required: true })
  protected currentIndex!: number;

  @Prop({ type: String, required: true })
  protected link!: string;

  @Prop({ type: String, required: true })
  protected imgSrc!: string;

  get isNoWebGL() {
    return mainStore.isNoWebGL;
  }

  get isCurrent() {
    return this.currentIndex === this.index;
  }

  get classObj() {
    return {
      'is-current': this.isCurrent
    }
  }

  mounted() {
  }
}

const NUM_ITEMS = 4;
const INTERVAL = 7000;

@Component({
  components: {
    item: IndexKVItem
  }
})
export default class IndexKV extends Vue {
  protected currentIndex: number = -1;
  protected timer: number = 0;
  protected isPlaying: boolean = false;

  get classObj() {
    return {
      'is-playing': this.isPlaying
    }
  }

  get styleObj() {
    return {
      height: `${mainStore.windowHeight}px`
    }
  }

  protected setTimer() {
    window.clearTimeout(this.timer);
    this.timer = window.setTimeout(()=> {
      this.setIndex((this.currentIndex + 1) % NUM_ITEMS);
    }, INTERVAL);
  }

  public play() {
    if(this.isPlaying) return;
    this.currentIndex = 0;
    this.isPlaying = true;
    this.setTimer();
  }

  public pause() {
    if(!this.isPlaying) return;
    this.currentIndex = -1;
    this.isPlaying = false;
    window.clearTimeout(this.timer);
  }

  @Ref('dummyVisual')
  public dummyVisual!: HTMLDivElement;

  public getImgContainer() {
    return this.dummyVisual;
  }

  @Emit('onclickindicator')
  protected onClickIndicator(index: number) {
    return index;
  }

  public setIndex(index: number) {
    this.currentIndex = index;
    this.onClickIndicator(index);
    this.setTimer();
  }

  protected onClickScroll() {
    g.scrollTo(mainStore.windowHeight);
  }

  mounted()  {
    window['setIndex'] = (index)=> {
      this.setIndex(index);
    }
  }


}