const g:any = window[ENV.projectName] = window[ENV.projectName] || {};

import Cookies from 'js-cookie';

import WebGLBase from './WebGLBase';
import PointCloud, { PointCloudDefinition } from './PointCloud';
import BgPointCloud from './BgPointCloud';
import OpeningPhotos from './OpeningPhotos';

import { Vector2 } from 'three/src/math/Vector2'     ;
import { Texture } from 'three/src/textures/Texture' ;

import preloadImg from '../../_utils/img/preloadImg';

import {
  gsap,
  Linear
} from 'gsap/src/gsap-core';
import { mainStore } from '../../_store/main';
import { UniformsLib } from 'three/src/renderers/shaders/UniformsLib';

const COOKIE_LABEL_IS_OPENING_PLAYED_ONCE = 'isOpeningPlayedOnce';

const PointImg = '/assets/img/common/point.png';


export default class MainGL<T extends PointCloudDefinition> extends WebGLBase {
  protected pointCloudInitialKey!: string;
  protected pointCloud!: PointCloud<T>;
  protected bgPointCloud!: BgPointCloud;
  protected openingPhotos!: OpeningPhotos;
  protected pointerX: number = 0;
  protected pointerY: number = 0;
  protected noOpeing: boolean = true;
  protected incrementNumLoaded: ()=> void = ()=> {};
  protected incrementNumTotal: ()=> void = ()=> {};

  public isOpeningSkipped: boolean = false;

  protected pointCloudDefinition!: T | null;

  constructor(container: HTMLElement, canvas: HTMLCanvasElement, pointCloudDefinition: T | null = null, pointCloudInitialKey: string = '', noOpeing: boolean = true) {
    super(container, canvas);
    this.pointCloudDefinition = pointCloudDefinition;
    this.pointCloudInitialKey = pointCloudInitialKey;
    this.noOpeing = noOpeing;

    window.addEventListener('mousemove', (e: MouseEvent)=> {
      this.pointerX = e.clientX;
      this.pointerY = e.clientY;
    })
    window.addEventListener('touchstart', (e: TouchEvent)=> {
      const touch = e.touches[0];
      this.pointerX = touch.clientX;
      this.pointerY = touch.clientY;
      this.setPointerActive(true);
    })
    window.addEventListener('touchmove', (e: TouchEvent)=> {
      const touch = e.touches[0];
      this.pointerX = touch.clientX;
      this.pointerY = touch.clientY;
    })
    window.addEventListener('touchend', (e: TouchEvent)=> {
      this.setPointerActive(false);
    })

    // window['setKVIndex'] = this.setKVIndex.bind(this);
    // window['setMode'] = this.setMode.bind(this);
  }

  public setCallbacks(incrementNumLoaded: ()=> void, incrementNumTotal: ()=> void) {
    this.incrementNumLoaded = incrementNumLoaded;
    this.incrementNumTotal = incrementNumTotal;
  }

  public setParams(params: { [key: string]: { imgSize: number, offset: Vector2 } }) {
    if(this.isNoWebGL || this.isDisposed) return;
    this.pointCloud?.setParams(params);
  }

  protected beforeRenderContents() {
    this.pointCloud?.update(
      this.time,
      this.pointerX - mainStore.windowWidth * 0.5,
      this.pointerY + mainStore.scrollTop - mainStore.glHeight * 0.5
    );
    this.bgPointCloud?.update(this.time);
    this.renderer.setRenderTarget(null);
  }

  protected async initContents() {
    const gl = this.renderer.getContext();
    console.log(gl.getSupportedExtensions()?.join("\n"));

    let isSimpleMode: boolean = false;

    console.log(gl.getExtension("OES_texture_float"));
    console.log(gl.getExtension("WEBGL_color_buffer_float"));
    console.log(gl.getExtension("EXT_color_buffer_half_float"));
    console.log(gl.getExtension("OES_texture_half_float_linear"));

    console.log(gl.getParameter(gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS));

    if(gl.getExtension("WEBGL_color_buffer_float") === null || g.isiOS || g.isAndroid) {
      isSimpleMode = true;
    }
    // isSimpleMode = true;

    const pointTexture = await this.loadPointTexture();

    const promises: Promise<any>[] = [];
    if(this.pointCloudDefinition) {
      this.pointCloud =  new PointCloud<T>(
        this.renderer,
        this.scene,
        isSimpleMode,
        this.pointCloudDefinition,
        this.pointCloudInitialKey,
        pointTexture
      );
      promises.push(this.pointCloud.init(this.incrementNumLoaded, this.incrementNumTotal));
    }

    if(Cookies.get(COOKIE_LABEL_IS_OPENING_PLAYED_ONCE) === '1' && !g.params.forceOpening || this.noOpeing) {
      // スキップ
      this.isOpeningSkipped = true;
    } else {
      this.openingPhotos = new OpeningPhotos(!!gl.getExtension("ANGLE_instanced_arrays"));
      promises.push(
        this.openingPhotos.init(this.incrementNumLoaded, this.incrementNumTotal)
        .catch((e)=> console.error(e))
      )
    }

    if(promises.length !== 0) await Promise.all(promises);

    this.bgPointCloud =  new BgPointCloud(pointTexture);
    this.scene.add(this.bgPointCloud.points);

    if(this.pointCloud) this.scene.add(this.pointCloud.points);
    if(this.openingPhotos) this.scene.add(this.openingPhotos.mesh);
    console.log(this.bgPointCloud, this.pointCloud, this.openingPhotos);
  }

  protected async loadPointTexture() {
    const img: HTMLImageElement = await preloadImg(PointImg);
    const pointTexture = new Texture(img);
    pointTexture.needsUpdate = true;
    return pointTexture;
  }

  public setKVIndex(index: number) {
    if(this.isNoWebGL || this.isDisposed) return;
    this.pointCloud?.setKVIndex(index);
  }

  public setMode(modeKey: string) {
    if(this.isNoWebGL || this.isDisposed) return;
    this.pointCloud?.setMode(modeKey);
  }

  public setScrollTop() {
    if(this.isNoWebGL || this.isDisposed) return;
    this.pointCloud?.setScrollTop();
    this.bgPointCloud?.setScrollTop();
  }

  public setOrange(isOrange: boolean) {
    if(this.isNoWebGL || this.isDisposed) return;
    this.pointCloud?.setOrange(isOrange);
    this.bgPointCloud?.setOrange(isOrange);
  }

  public setPointerActive(isPointerActive: boolean) {
    if(this.isNoWebGL || this.isDisposed) return;
    this.pointCloud?.setPointerActive(isPointerActive? 1: 0);
  }

  public play(callback: ()=> void = ()=> {}, opening: boolean = true) {
    if(this.isNoWebGL || this.isDisposed) {
      callback();
      return;
    };

    if(this.isOpeningSkipped || !opening) {
      this.pointCloud?.play();
      this.bgPointCloud?.play();
      callback();
    } else {
      window.scrollTo(0, 0);
      this.openingPhotos?.play(()=> {
        this.isOpeningSkipped = true;
        Cookies.set(COOKIE_LABEL_IS_OPENING_PLAYED_ONCE, 1);
        this.pointCloud?.play();
        this.bgPointCloud?.play();
        callback();
      });
    }
  }

  protected onResizeContents() {
    mainStore.setGLHeight(this.container.offsetHeight);
    this.openingPhotos?.onResize();
  }
}
