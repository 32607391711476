const g = (window[ENV.projectName] = window[ENV.projectName] || {});

// intersectionObserver polyfil
require('intersection-observer');

// ------------------------------
// unique Class for each case
// ------------------------------
import WebFont from "webfontloader";

import checkDevice from "../_utils/ua/checkDevice";
g.md = checkDevice();

// if(g.md.tablet()) {
//   g.isTablet = true;
//   const _WIDTH = 1200;
//   if(window.innerWidth < _WIDTH) {
//     const initilScale = window.innerWidth / _WIDTH;
//     document.querySelector('meta[name="viewport"]').setAttribute('content', `width=${_WIDTH},initial-scale=${initilScale}`);
//   }
// } else if(g.md.mobile()) {
//   g.isMobile = true;
// } else {
//   g.isDesktop = true;
// }

const html = document.querySelector("html");

import isiOS from "../_utils/ua/isiOS";
import isAndroid from "../_utils/ua/isAndroid";
import isIE11 from "../_utils/ua/isIE11";
import isEdge from "../_utils/ua/isEdge";
import isWindows from "../_utils/ua/isWindows";
import isChrome from "../_utils/ua/isChrome";
import isFirefox from "../_utils/ua/isFirefox";
import isGoogleBot from "../_utils/ua/isGoogleBot";

const ua = navigator && navigator.userAgent;
if (ua) {
  g.isiOS = isiOS(ua);
  g.isAndroid = isAndroid(ua);
  g.isIE11 = isIE11(ua);
  g.isEdge = isEdge(ua);
  g.isWindows = isWindows(ua);
  g.isGoogleBot = isGoogleBot(ua);
  g.isChrome = !g.isEdge && isChrome(ua);
  g.isFirefox = !g.isEdge && isFirefox(ua);

  if (g.isiOS) html.classList.add("is-ios");
  if (g.isAndroid) html.classList.add("is-android");
  if (g.isIE11) html.classList.add("is-ie11");
  if (g.isEdge) html.classList.add("is-edge");
  if (g.isGoogleBot) html.classList.add("is-windows");
  if (g.isFirefox) html.classList.add("is-firefox");
  if (g.isGoogleBot) html.classList.add("is-googlebot");
  if (g.isWindows && g.isChrome) html.classList.add("is-winchrome");
}

// get params
g.params = {};
location.search
  .replace("?", "")
  .split("&")
  .forEach(str => {
    let key, value;
    [key, value] = str.split("=");
    g.params[key] = value;
  });

// // Adobe fonts
// const adobeFontConfig = {
//   // kitId: "mvv5zjz", // 開発用
//   kitId: "fvw2qve",
//   scriptTimeout: 5000,
//   async: false
// }
// const loadTypekitFont = (onLoad = ()=> {}, onError = ()=> {})=> {
//   let config = {
//     ...adobeFontConfig,
//     active: ()=> {
//       console.log('adboe font active');
//       onLoad()
//     },
//     inactive: ()=> {
//       console.error('adboe font inactive');
//       onError()
//     }
//   };

//   try {
//     Typekit.load(config);
//   } catch (e) {
//     console.error(e);
//     onError()
//   }
// }
// const typeKitPromise = new Promise((resolve)=> {
//   (function(d) {
//     var h = d.documentElement,
//       t = setTimeout(function() {
//         h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
//         console.log('load adobe fonts timeout');
//         resolve("load adobe fonts timeout");
//       }, adobeFontConfig.scriptTimeout),
//       tk = d.createElement("script"),
//       f = false,
//       s = d.getElementsByTagName("script")[0],
//       a;
//     h.className += " wf-loading";
//     tk.src = "https://use.typekit.net/" + adobeFontConfig.kitId + ".js";
//     tk.async = true;
//     tk.onload = tk.onreadystatechange = function() {
//       a = this.readyState;
//       if (f || (a && a != "complete" && a != "loaded")) return;
//       f = true;
//       clearTimeout(t);
//       loadTypekitFont(()=> resolve());
//     };
//     s.parentNode.insertBefore(tk, s);
//   })(document);
// })

const webFontLoaderPromise = new Promise((resolve, reject) => {
  console.log("loading fonts...");
  WebFont.load({
    google: {
      families: ["Cutive", "Noto+Sans+JP:400,700", "Roboto:100"]
    },

    custom: {
      families: ['YakuHanJP_Noto'],
      url: '/assets/css/style.css'
    },

    // typekit: {
    //   id: "mvv5zjz"
    // },

    timeout: 5000,

    fontactive: (familyName, fvd) => {
      console.log("fontactive", familyName, fvd);
    },
    fontinactive: (familyName, fvd) => {
      console.log("fontinactive", familyName, fvd);
    },

    active: () => {
      console.log("all fonts loaded");
      // this.store.commit('addLoadingCurrent');
      resolve();
    },

    inactive: () => {
      console.error("font inactive");
      // this.store.commit('addLoadingCurrent');
      resolve("load fonts error");
    }
  });
});

g.loadFontPromise = Promise.all([
  webFontLoaderPromise,
  // typeKitPromise,
])

// const tag = document.createElement('script');
// tag.src = "https://www.youtube.com/iframe_api";
// const firstScriptTag = document.getElementsByTagName('script')[0];
// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
// let youtubeAPIReadyPromiseResolver;

// window.onYouTubeIframeAPIReady = ()=> {
//   console.log('YouTube API is ready')
//   youtubeAPIReadyPromiseResolver();
// }

// g.youtubeAPIReadyPromise = new Promise((resolve, reject)=> {
//   youtubeAPIReadyPromiseResolver = resolve;

//   if(window.YT && window.YT.loaded) {
//     resolve();
//     return;
//   }

//   setTimeout(()=> {
//     reject('YouTube API load timeout');
//   }, 100000);
// })

// let isTwitterWidgetLoaded = false;
// g.getTwitterWidgetLoaded = ()=> {
//   return isTwitterWidgetLoaded;
// }
// g.twitterWidgetReadyPromise = new Promise((resolve, reject)=> {
//   if(window.twttr && window.twttr.init) {
//     resolve();
//     return;
//   }

//   window.twttr = (function(d, s, id) {
//     var js, fjs = d.getElementsByTagName(s)[0],
//       t = window.twttr || {};
//     if (d.getElementById(id)) return t;
//     js = d.createElement(s);
//     js.id = id;
//     js.src = "https://platform.twitter.com/widgets.js";
//     fjs.parentNode.insertBefore(js, fjs);

//     t._e = [];
//     t.ready = function(f) {
//       t._e.push(f);
//     };
//     t.ready(()=> {
//       isTwitterWidgetLoaded = true;
//       resolve();
//     })
//     return t;
//   }(document, "script", "twitter-wjs"));

//   setTimeout(()=> {
//     reject('Twitter Widget API load timeout');
//   }, 100000);
// })