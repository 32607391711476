import { createTrue } from 'typescript';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import { LayoutModeProp, SimpleLayoutModeProp } from '../_types/common';
import store from './';

export type MainState = {
  isRootLoaded: boolean;
  isScrolled: boolean;
  isInTransition: boolean;
  isTransitOnce: boolean;
  isMenuOpened: boolean;
  isModalOpened: boolean;
  isNoWebGL: boolean;
  isSoundOn: boolean;

  scrollTop: number;
  scrollLeft: number;
  windowWidth: number;
  windowHeight: number;
  glHeight: number;

  layoutMode: LayoutModeProp;
  simpleLayoutMode: SimpleLayoutModeProp;

  currentPageId: string;
  nextPageId: string;
}

@Module({ dynamic: true, store, name: 'app' })
export default class Main extends VuexModule implements MainState {
  public isRootLoaded = false;
  public isScrolled = false;
  public isInTransition = false;
  public isTransitOnce = false;
  public isMenuOpened = false;
  public isModalOpened = false;
  public isNoWebGL = false;
  public isSoundOn = false;

  public scrollTop = 0;
  public scrollLeft = 0;
  public windowWidth = 0;
  public windowHeight = 0;
  public glHeight = 0;

  public layoutMode: LayoutModeProp = 'S';
  public simpleLayoutMode: SimpleLayoutModeProp = 'S';

  public currentPageId = 'index';
  public nextPageId = 'index';

  @Mutation
  public setLoaded(isRootLoaded: boolean): void {
    this.isRootLoaded = isRootLoaded;
  }

  @Mutation
  public setScrolled(isScrolled: boolean): void {
    this.isScrolled = isScrolled;
  }

  @Mutation
  public setInTransition(isInTransition: boolean): void {
    this.isInTransition = isInTransition;
  }

  @Mutation
  public setTransitOnce(isTransitOnce: boolean): void {
    this.isTransitOnce = isTransitOnce;
  }

  @Mutation
  public openMenu(): void {
    this.isMenuOpened = true;
  }
  @Mutation
  public closeMenu(): void {
    this.isMenuOpened = false;
  }
  @Mutation
  public setMenuOpened(isMenuOpened: boolean): void {
    this.isMenuOpened = isMenuOpened;
  }

  @Mutation
  public openModal(): void {
    this.isModalOpened = true;
  }
  @Mutation
  public closeModal(): void {
    this.isModalOpened = false;
  }
  @Mutation
  public setModalOpened(isModalOpened: boolean): void {
    this.isModalOpened = isModalOpened;
  }


  @Mutation
  public setScrollTop(scrollTop: number): void {
    this.scrollTop = scrollTop;
  }

  @Mutation
  public setScrollLeft(scrollLeft: number): void {
    this.scrollLeft = scrollLeft;
  }

  @Mutation
  public setWindowSize({ windowWidth, windowHeight }: { windowWidth: number, windowHeight: number }): void {
    this.windowWidth = windowWidth;
    this.windowHeight = windowHeight;
  }

  @Mutation
  public setGLHeight(glHeight: number): void {
    this.glHeight = glHeight;
  }


  @Mutation
  public setLayoutMode(layoutMode: LayoutModeProp): void {
    this.layoutMode = layoutMode;
  }

  @Mutation
  public setSimpleLayoutMode(simpleLayoutMode: SimpleLayoutModeProp): void {
    this.simpleLayoutMode = simpleLayoutMode;
  }


  @Mutation
  public setCurrentPageId(currentPageId: string): void {
    this.currentPageId = currentPageId;
  }

  @Mutation
  public setNextPageId(nextPageId: string): void {
    this.nextPageId = nextPageId;
  }

  @Mutation
  public setNoWebGL(isNoWebGL: boolean): void {
    this.isNoWebGL = isNoWebGL;
  }

  @Mutation
  public setSoundOn(isSoundOn: boolean): void {
    this.isSoundOn = isSoundOn;
  }
}


export const mainStore = getModule(Main);