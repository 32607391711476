import { Component, Vue, Prop, Emit, Ref } from 'vue-property-decorator';
import { mainStore } from '../_store/main';
import OnScreenItem from './OnScreenItem';

const g:any = window[ENV.projectName] = window[ENV.projectName] || {};


@Component
export default class OnScreenImg extends OnScreenItem {
  protected isLoaded: boolean = false;
  protected type: 'image'|'video' = 'image';

  @Ref('img')
  protected img!: HTMLImageElement | HTMLVideoElement;

  mounted() {
    this.type = this.img instanceof HTMLImageElement? 'image': 'video';
    this.onLoadImg = this.onLoadImg.bind(this);
    this.img.addEventListener((this.type === 'image'? 'load': 'canplay'), this.onLoadImg, { once: true })
  }

  protected onLoadImg(e: Event) {
    this.img.removeEventListener((this.type === 'image'? 'load': 'canplay'), this.onLoadImg);
    this.isLoaded = true;
  }

  get classObj() {
    return {
      'is-onScreenImgLoaded': this.isLoaded,
      'is-onScreen': this.isOnScreen,
      'is-onScreenImg': this.isAvailable,
      'is-onScreenItem': this.isAvailable,
      'is-video': this.type === 'video'
    }
  }
}