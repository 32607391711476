import { Component, Vue, Prop, Emit, Ref } from 'vue-property-decorator';
import { mainStore } from '../_store/main';

const g:any = window[ENV.projectName] = window[ENV.projectName] || {};

@Component
export default class PageKV extends Vue {
  protected isPlaying: boolean = false;

  get classObj() {
    return {
      'is-playing': this.isPlaying
    }
  }

  get styleObj() {
    return {
      height: `${mainStore.windowHeight}px`
    }
  }

  public play() {
    if(this.isPlaying) return;
    this.isPlaying = true;
  }

  @Ref('dummyVisual')
  public dummyVisual!: HTMLDivElement;

  public getImgContainer() {
    return this.dummyVisual;
  }

  get isNoWebGL() {
    return mainStore.isNoWebGL;
  }

  mounted()  {
  }
}