const g:any = window[ENV.projectName] = window[ENV.projectName] || {};

import { Component, Vue, Emit, Ref } from 'vue-property-decorator';
import { mainStore } from '../_store/main';

@Component
export default class Footer extends Vue {
  get classObj() {
    return {
      'is-menuOpened': mainStore.isMenuOpened,
    }
  }
}