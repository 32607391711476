const g:any = window[ENV.projectName] = window[ENV.projectName] || {};

import gsap, {
  Expo,
  Linear,
  Sine,
  Cubic
} from 'gsap/src/gsap-core';

import preloadImg from '../../_utils/img/preloadImg';
import { mainStore } from '../../_store/main';
import InstancedBufferGeometryBuilder from './InstancedBufferGeometryBuilder';

import { Texture             } from 'three/src/textures/Texture'            ;
import { RawShaderMaterial   } from 'three/src/materials/RawShaderMaterial' ;
import { Vector2             } from 'three/src/math/Vector2'                ;
import { BufferGeometry      } from 'three/src/core/BufferGeometry'         ;
import { Mesh                } from 'three/src/objects/Mesh'                ;
import { PlaneBufferGeometry } from 'three/src/geometries/PlaneGeometry'    ;
import { Vector3             } from 'three/src/math/Vector3'                ;
import { LinearFilter        } from 'three/src/constants'                   ;

const NUM_PHOTOS = 6;
const NUM_TEXTURES = 2;
const PLANE_SIZE = 100;
const POS_Z = 1000;
const POS_RADIUS_BASIS = 140;

const SCALE_BASIS = 6 / 1300;

type TextureData = {
  width: number
  height: number
  aspectRatio: number
}

// intro_.jpg 5361 × 3574
// intro.jpg 1280 × 1280
// iStock-517373440.jpg 5650 × 3761
// iStock-542082038 (1).jpg 2121 × 1414
// iStock-544978128.jpg 1255 × 835
// iStock-1194324939.jpg 2121 × 1414
const TEXTURE_DATA: TextureData[] = [
  { width: 5361 , height: 3574, aspectRatio: 1 },
  { width: 1280 , height: 1280, aspectRatio: 1 },
  { width: 5650 , height: 3761, aspectRatio: 1 },
  { width: 2121 , height: 1414, aspectRatio: 1 },
  { width: 1255 , height: 835 , aspectRatio: 1 },
  { width: 2121 , height: 1414, aspectRatio: 1 },
];
TEXTURE_DATA.forEach((value: TextureData)=> {
  value.aspectRatio = value.width / value.height;
})


const TEXTURE_PATH_BASE = '/assets/img/index/opening/';

export default class OpeningPhotos {
  public mesh!: Mesh;
  protected material!: RawShaderMaterial;
  protected geomery!: BufferGeometry;
  protected textures: Texture[] = [];
  protected isSupportedInstancedArray: boolean = false;

  protected incrementNumLoaded!: ()=> void;
  protected incrementNumTotal!: ()=> void;

  constructor(isSupportedInstancedArray: boolean) {
    this.isSupportedInstancedArray =  isSupportedInstancedArray;
  }

  protected shuffle(arr: any[]) {
    const _arr = arr.slice();
    for(var i = _arr.length - 1; i > 0; i--){
      var r = Math.floor(Math.random() * (i + 1));
      var tmp = _arr[i];
      _arr[i] = _arr[r];
      _arr[r] = tmp;
    }
    return _arr;
  }

  public async init(incrementNumLoaded: ()=> void, incrementNumTotal: ()=> void) {
    this.incrementNumLoaded = incrementNumLoaded;
    this.incrementNumTotal = incrementNumTotal;

    await this.initTextures();

    this.material = new RawShaderMaterial({
      vertexShader: require('./glsl/openingPhotos.vert').default,
      fragmentShader: require('./glsl/openingPhotos.frag').default,
      transparent: true,
      depthWrite: false,
      depthTest: false,
      uniforms: {
        resolution: { value: new Vector2() },
        scale: { value: 1 },
        animationValue: { value: 0 }
      }
    });

    for (let i = 0; i < NUM_TEXTURES; i++) {
      this.material.uniforms[`photoTexture${i}`] = { value: this.textures[i] }
    }
    this.material.needsUpdate = true;

    // geometry
    const geometryBuilder = new InstancedBufferGeometryBuilder(NUM_PHOTOS, new PlaneBufferGeometry(PLANE_SIZE, PLANE_SIZE), this.isSupportedInstancedArray);

    let textureData: TextureData;
    let planeScale: Vector2[] = [];
    let posTo: Vector3[] = [];
    let posFrom: Vector3[] = [];
    let textureIndices: number[] = [];
    let textureUVOffsets: Vector2[] = [];
    let angle: number, r: number;
    let texColIndex: number, texRowIndex: number;
    const randomIndices = this.shuffle([...Array(NUM_PHOTOS)].map((_, i) => i));

    for (let i = 0; i < NUM_PHOTOS; i++) {
      textureData = TEXTURE_DATA[i];

      planeScale.push(
        (textureData.aspectRatio > 1)? new Vector2(1, 1 / textureData.aspectRatio):
        new Vector2(textureData.aspectRatio, 1)
      );

      texColIndex = i % 2;
      texRowIndex = Math.floor(i / 2) % 2;

      textureUVOffsets.push(new Vector2(
        texColIndex * 0.5,
        0.5 - texRowIndex * 0.5
      ));

      textureIndices.push(Math.floor(i / 4));

      angle = Math.PI * 2 * Math.random();
      r = Math.sqrt(Math.random()) * POS_RADIUS_BASIS * 0.1;
      posFrom.push(new Vector3( r * Math.cos(angle), r * Math.sin(angle), -POS_Z ));

      angle = randomIndices.shift() * Math.PI * 2 / NUM_PHOTOS;
      r = Math.sqrt(Math.random()) * POS_RADIUS_BASIS * 0.6 + 0.4 * POS_RADIUS_BASIS;
      posTo.push(new Vector3( r * Math.cos(angle), r * Math.sin(angle), POS_Z ));

    }

    // this.material.needsUpdate = true;
    geometryBuilder.setAttribute('planeScale', planeScale, 2);
    geometryBuilder.setAttribute('posFrom', posFrom, 3);
    geometryBuilder.setAttribute('posTo', posTo, 3);
    geometryBuilder.setAttribute('textureIndex', textureIndices, 1);
    geometryBuilder.setAttribute('textureUVOffset', textureUVOffsets, 2);
    this.geomery = geometryBuilder.getBefferGeometry();

    this.mesh = new Mesh(this.geomery, this.material);
  }


  protected async initTexture(index: number) {
    this.incrementNumTotal();

    const img: HTMLImageElement  = await preloadImg(`${TEXTURE_PATH_BASE}${index}.jpg`)
    const texture = new Texture(img);
    texture.generateMipmaps = false;
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;
    texture.needsUpdate = true;
    this.textures[index] = texture;
    this.incrementNumLoaded();
  }

  protected async initTextures() {
    const promises: Promise<any>[] = [];
    for (let i = 0; i < NUM_TEXTURES; i++) {
      promises.push(this.initTexture(i));
    }
    return Promise.all(promises);
  }


  public play(callback: ()=> void) {
    // const duration = 7.6;
    const duration = 8;
    gsap.killTweensOf(this.material.uniforms.animationValue);
    gsap.to(this.material.uniforms.animationValue, duration, { ease: Linear.easeNone, value: 1, onComplete: ()=> {
      this.mesh.parent?.remove(this.mesh);
    } });
    window.setTimeout(()=> {
      callback();
    }, duration * 1000 - 2000)
  }

  public onResize() {
    this.material.uniforms.scale.value = mainStore.glHeight * SCALE_BASIS;
  }
}
