export const LayoutMode = {
  SS: 'SS',
  S: 'S',
  M: 'M',
  L: 'L',
  LL: 'LL',
} as const
export type LayoutModeProp = typeof LayoutMode[keyof typeof LayoutMode];

export const SimpleLayoutMode = {
  S: 'S',
  L: 'L',
} as const
export type SimpleLayoutModeProp = typeof SimpleLayoutMode[keyof typeof SimpleLayoutMode];

export type VisualData = {
  src: string,
  width: number,
  height: number
}

