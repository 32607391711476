import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { mainStore } from '../_store/main';

const g:any = window[ENV.projectName] = window[ENV.projectName] || {};

@Component
export default class OnScreenItem extends Vue {
  protected onScreenId!: string;
  protected observer!: IntersectionObserver;
  protected isOnScreen: boolean = false;
  protected isIntersected: boolean = false;
  protected isInited: boolean = false;
  protected autoExec: boolean = false;
  protected target!: HTMLElement;

  @Prop({ type: HTMLElement, required: false })
  protected targetEl!:HTMLElement;

  @Prop({ type: Boolean, required: false, default: true })
  protected onceMode!:boolean;

  @Prop({ type: String, required: false, default: '' })
  protected name!:string;

  // emit
  @Emit()
  public create(): OnScreenItem {
    return this;
  }

  @Emit()
  public mount(): OnScreenItem {
    return this;
  }

  // lifecycle hooks
  created() {
    this.create();
  }

  mounted() {
    this.target = (this.targetEl || this.$el) as HTMLElement;
    this.mount();
  }

  destroyed() {
    if(this.observer && this.target) {
      this.observer.unobserve(this.target);
    }
  }

  // computed
  get isAvailable(): boolean {
    return this.isInited && mainStore.isRootLoaded && !mainStore.isInTransition;
  }

  get classObj() {
    return {
      'is-onScreen': this.isOnScreen,
      'is-onScreenItem': this.isAvailable
    }
  }

  // methods
  public async initOnScreen(onScreenId: string, observer: IntersectionObserver, attributeName: string): Promise<any> {
    this.onScreenId = onScreenId;
    this.observer = observer;
    this.observer.observe(this.target);
    this.$el.setAttribute(attributeName, onScreenId);
    await this.$nextTick();
    this.isInited = true;
  }

  public onIntersected(entry: IntersectionObserverEntry): void {
    this.isIntersected = true;
    if(!this.isAvailable) return;
    if(this.autoExec) this.exec();
  }

  public exec(force: boolean = false): void {
    if(force || (this.isAvailable && this.isIntersected && !this.isOnScreen)) {
      this.isOnScreen = true;
      if(this.onceMode) this.observer.unobserve(this.target);
    }
  }

  public setAutoExec(autoExec: boolean = true): void {
    this.autoExec = autoExec;
  }

  public getOnScreen() {
    return this.isOnScreen;
  }
}