import PseudoInstancedBufferGeometry from './PseudoInstancedBufferGeometry';

import { InstancedBufferGeometry  } from 'three/src/core/InstancedBufferGeometry' ;
import { InstancedBufferAttribute } from 'three/src/core/InstancedBufferAttribute';
import { BufferGeometry           } from 'three/src/core/BufferGeometry'          ;
import { BufferAttribute          } from 'three/src/core/BufferAttribute'         ;

export default class InstancedBufferGeometryBuilder {
  protected numInstances!: number;
  protected baseBufferGeometry!: BufferGeometry;
  protected isSupportedInstancedArray: boolean = false;
  protected numPositions: number = 0;
  protected ibGeometry!: InstancedBufferGeometry | PseudoInstancedBufferGeometry;

  constructor(numInstances, baseBufferGeometry, isSupportedInstancedArray = true) {
    this.numInstances = numInstances;
    this.baseBufferGeometry = baseBufferGeometry;

    this.isSupportedInstancedArray = isSupportedInstancedArray;

    this.numPositions = this.baseBufferGeometry.attributes.position.array.length;

    if (this.isSupportedInstancedArray) {
      console.log('ANGLE_instanced_arrays is supported');
      this.initTHREEIBGeometry();
    } else {
      console.log('ANGLE_instanced_arrays is not supported');
      this.ibGeometry = new PseudoInstancedBufferGeometry(this.numInstances, this.baseBufferGeometry);
    }
  }

  // init InstancedBufferGeometry
  protected initTHREEIBGeometry(): void {
    this.ibGeometry = new InstancedBufferGeometry();
    const instanceIndices = new InstancedBufferAttribute(new Float32Array(this.numInstances), 1);
    const randomValues = new InstancedBufferAttribute(new Float32Array(this.numInstances * 3), 3);

    for (let i = 0, l = this.numInstances; i < l; i++) {
      randomValues.setXYZ(i, this.getRandomValue(), this.getRandomValue(), this.getRandomValue());
      instanceIndices.setX(i, i);
    }
    // attributes
    const attribute = this.baseBufferGeometry?.attributes;
    this.ibGeometry.setAttribute('position' , (attribute.position as BufferAttribute) .clone());
    this.ibGeometry.setAttribute('uv'       , (attribute.uv       as BufferAttribute) .clone());
    this.ibGeometry.setAttribute('normal'   , (attribute.normal   as BufferAttribute) .clone());
    this.ibGeometry.setAttribute('instanceIndex', instanceIndices);
    this.ibGeometry.setAttribute('randomValues', randomValues);
    // index
    if(this.baseBufferGeometry.index) this.ibGeometry.setIndex(this.baseBufferGeometry.index.clone());
    this.ibGeometry.computeVertexNormals();
  }

  public getRandomValue(): number {
    return (Math.random() + Math.random() + Math.random()) / 3;
  }

  public getBefferGeometry(): InstancedBufferGeometry | PseudoInstancedBufferGeometry {
    return this.ibGeometry;
  }

  public setAttribute(
    attributeName: string,
    data: any[],
    itemSize: number,
    dataLabelX: string | number = 'x',
    dataLabelY: string | number = 'y',
    dataLabelZ: string | number = 'z',
    dataLabelW: string | number = 'w'
  ): void {
    var attribute, index;
    if (this.isSupportedInstancedArray) {
      attribute = new InstancedBufferAttribute(new Float32Array(this.numInstances * itemSize), itemSize);
      if (itemSize === 1) {
        for(let i = 0, l = this.numInstances; i < l; i++) {
          attribute.setX(i, data[i]);
        }
      } else if (itemSize === 2) {
        for(let i = 0, l = this.numInstances; i < l; i++) {
          attribute.setXY(i, data[i][dataLabelX], data[i][dataLabelY]);
        }
      } else if (itemSize === 3) {
        for(let i = 0, l = this.numInstances; i < l; i++) {
          attribute.setXYZ(i, data[i][dataLabelX], data[i][dataLabelY], data[i][dataLabelZ]);
        }
      } else if (itemSize === 4) {
        for(let i = 0, l = this.numInstances; i < l; i++) {
          attribute.setXYZW(i, data[i][dataLabelX], data[i][dataLabelY], data[i][dataLabelZ], data[i][dataLabelW]);
        }
      }
    } else {
      attribute = new BufferAttribute(new Float32Array(this.numInstances * this.numPositions / 3 * itemSize), itemSize);
      if (itemSize === 1) {
        for(let i = 0, l = this.numInstances; i < l; i++) {
          for(let j = 0, l2 = this.numPositions / 3; j < l2; j++) {
            index = i * this.numPositions / 3 + j;
            attribute.setX(index, data[i]);
          }
        }
      } else if (itemSize === 2) {
        for(let i = 0, l = this.numInstances; i < l; i++) {
          for(let j = 0, l2 = this.numPositions / 3; j < l2; j++) {
            index = i * this.numPositions / 3 + j;
            attribute.setXY(index, data[i][dataLabelX], data[i][dataLabelY]);
          }
        }
      } else if (itemSize === 3) {
        for(let i = 0, l = this.numInstances; i < l; i++) {
          for(let j = 0, l2 = this.numPositions / 3; j < l2; j++) {
            index = i * this.numPositions / 3 + j;
            attribute.setXYZ(index, data[i][dataLabelX], data[i][dataLabelY], data[i][dataLabelZ]);
          }
        }
      } else if (itemSize === 4) {
        for(let i = 0, l = this.numInstances; i < l; i++) {
          for(let j = 0, l2 = this.numPositions / 3; j < l2; j++) {
            index = i * this.numPositions / 3 + j;
            attribute.setXYZW(index, data[i][dataLabelX], data[i][dataLabelY], data[i][dataLabelZ], data[i][dataLabelW]);
          }
        }
      }
    }
    this.ibGeometry.setAttribute(attributeName, attribute);
  }
};
