import { BufferGeometry } from 'three/src/core/BufferGeometry';
import { BufferAttribute } from 'three/src/core/BufferAttribute';

export default class VirtualInstancedBufferGeometry extends BufferGeometry {
  protected numInstances!: number;
  protected baseBufferGeometry!: BufferGeometry;

  protected vertices       : number[] = [];
  protected uvs            : number[] = [];
  protected indices        : number[] = [];
  protected instanceIndices: number[] = [];
  protected randomValues   : number[] = [];

  constructor(numInstances: number, baseBufferGeometry: BufferGeometry) {
    super();

    this.numInstances = numInstances;
    this.baseBufferGeometry = baseBufferGeometry;

    const baseBufferGeometryPositions = this.baseBufferGeometry.attributes.position.array;
    const numInstancePositions = baseBufferGeometryPositions.length;
    const baseBufferGeometryIndices = this.baseBufferGeometry?.index?.array || [];
    const baseBufferGeometryUVs = this.baseBufferGeometry.attributes.uv.array;

    for (let i = 0, l = this.numInstances; i < l; i++) {
      const randomValues: number[] = [this.getRandomValue(), this.getRandomValue(), this.getRandomValue()];
      for (let j = 0, l2 = numInstancePositions / 3; j < l2; j++) {
        this.vertices.push(baseBufferGeometryPositions[j * 3 + 0]);
        this.vertices.push(baseBufferGeometryPositions[j * 3 + 1]);
        this.vertices.push(baseBufferGeometryPositions[j * 3 + 2]);
        this.uvs.push(baseBufferGeometryUVs[j * 2 + 0]);
        this.uvs.push(baseBufferGeometryUVs[j * 2 + 1]);
        this.randomValues.push(randomValues[0]);
        this.randomValues.push(randomValues[1]);
        this.randomValues.push(randomValues[2]);
        this.instanceIndices.push(i);
      }
      for (let j = 0, l2 = numInstancePositions / 3; j < l2; j++) {
        this.indices.push(baseBufferGeometryIndices[j] + numInstancePositions / 3 * i);
      }
    }
    // attributes
    this.addAttribute('position', new BufferAttribute(new Float32Array(this.vertices), 3));
    this.addAttribute('uv', new BufferAttribute(new Float32Array(this.uvs), 2));
    this.addAttribute('instanceIndex', new BufferAttribute(new Uint16Array(this.instanceIndices), 1));
    this.addAttribute('randomValues', new BufferAttribute(new Float32Array(this.randomValues), 3));
    this.setIndex(new BufferAttribute(new Uint16Array(this.indices), 1));

    // 配列としては使用しないので、メモリ解放
    delete this.vertices;
    delete this.uvs;
    delete this.indices;
    delete this.instanceIndices;
    delete this.randomValues;
    this.computeVertexNormals();
  }

  public getRandomValue(): number {
    return (Math.random() + Math.random() + Math.random()) / 3;
  }

};
